import React, {
    Component
} from 'react';

import Crowding from './Crowding.js';
import {
    Flipped
} from "react-flip-toolkit";
import Prediction from './Prediction.js';
import RowAlerts from './RowAlerts.js';
import lokalise from '../../localization/lokalise.helper';

/*
    TODO
    change from state to variable + props
*/

class MassTransitRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            routeIcon: {
                routeNumber: this.props.massTransitRow[0].short_route,
                style: {
                    backgroundColor: this.props.massTransitRow[0].route_color,
                    color: this.props.massTransitRow[0].text_color
                }
            },


        };
    }

    componentDidMount() {

    }

    render() {

        const hasDirectionInfo = this.props.massTransitRow[0].direction || this.props.massTransitRow[0].platform || this.props.massTransitRow[0].via.length;

        const destinationInfo = this.props.massTransitRow[0].destination ? this.props.massTransitRow[0].destination + '' : this.props.massTransitRow[0].full_route;

        return (

            <Flipped flipId={this.props.id}>

                <div id={this.props.id} className="content-container__row masstransit__row">

                    <div className="masstransit__row__inner">
                        <span className="masstransit__row__route">
                            <span className="masstransit__row__route-color" style={this.state.routeIcon.style}></span>
                            <span className="masstransit__row__route-text" dangerouslySetInnerHTML={{ __html: this.props.massTransitRow[0].short_route }}></span>
                        </span>
                        <span className="masstransit__row__destination">
                            <span className="masstransit__row__destination__name">
                                {destinationInfo}
                            </span>
                            {hasDirectionInfo ? <span className="masstransit__row__destination__direction">
                                {this.props.massTransitRow[0].platform !== null && this.props.massTransitRow[0].platform !== "" ? <span>{this.props.localization.translations[this.props.localization.currentLanguage]["ts4z"] + " " + this.props.massTransitRow[0].platform + " "}</span> : ""}
                                {this.props.massTransitRow[0].direction !== null ? <span>{this.props.localization.translations[this.props.localization.currentLanguage][this.props.massTransitRow[0].direction.toLowerCase()] ? this.props.localization.translations[this.props.localization.currentLanguage][this.props.massTransitRow[0].direction.toLowerCase()] : this.props.massTransitRow[0].direction}</span> : ""}
                                {this.props.massTransitRow[0].via.length !== 0 && this.props.massTransitRow[0].via[0] !== null ? <span>{lokalise.replaceStrings(this.props.localization.translations[this.props.localization.currentLanguage]["ts4y"], { cities: "" }) + this.props.massTransitRow[0].via[0]}</span> : ""}
                            </span> : null}
                        </span>
                        {
                            this.props.screenData && this.props.screenData?.show_crowding ?
                                <span className="masstransit__row__crowding">
                                    <Crowding row={this.props.massTransitRow[0]} />
                                </span> :
                                null
                        }
                        <span className="masstransit__row__prediction">
                            <Prediction row={this.props.massTransitRow} timezone={this.props.screenData && this.props.screenData.timezone} lang={this.props.localization.translations[this.props.localization.currentLanguage]} />
                        </span>
                    </div>

                    <RowAlerts {...this.props} />

                </div>

            </Flipped>
        );
    }
}

MassTransitRow.displayName = "MassTransitRow";
export default MassTransitRow;