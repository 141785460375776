import React, {
	Component
} from 'react';

import {
	Flipped
} from "react-flip-toolkit";

import Moment from 'react-moment'; // https://momentjs.com/
import 'moment-timezone';
import lokalise from '../../../localization/lokalise.helper';
import {
	lang
} from 'moment';

/*
	TODO
	change from state to variable + props
*/

class MassTransitRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			routeIcon: {
				routeNumber: this.props.massTransitRow[0].short_route,
				style: {
					backgroundColor: this.props.massTransitRow[0].route_color,
					color: this.props.massTransitRow[0].text_color
				}
			},
		};
	}

	componentDidMount() {

	}

	render() {

		let directionText = this.props.massTransitRow[0].direction !== null ? this.props.localization.translations[this.props.localization.currentLanguage][this.props.massTransitRow[0].direction.toLowerCase()] : null;
		if (directionText) directionText = directionText.replace('bound', '');

		return (
			<Flipped flipId={this.props.id}>

			<div id={this.props.id} className="content-block__row masstransit__row">
				<div className="masstransit__row__inner">
					<span className="masstransit__row__icon" style={this.state.routeIcon.style} dangerouslySetInnerHTML={{__html: this.props.massTransitRow[0].short_route }}></span> {/* doing it this way because react doesn't love unicode */}
					<span className="masstransit__row__destination">
						<span className="masstransit__row__destination__name">
							{this.props.massTransitRow[0].destination ? this.props.massTransitRow[0].destination + '' : this.props.massTransitRow[0].full_route}
						</span>
						<span className="masstransit__row__destination__direction">
							{this.props.massTransitRow[0].platform !== null && this.props.massTransitRow[0].platform !== "" ? <span>{this.props.localization.translations[this.props.localization.currentLanguage]["ts4z"] + " " + this.props.massTransitRow[0].platform + " "}</span>: "" }
							{directionText ? <span>{directionText}</span> : "" }
							{this.props.massTransitRow[0].via.length !== 0 && this.props.massTransitRow[0].via[0] !== null ? <span>{lokalise.replaceStrings(this.props.localization.translations[this.props.localization.currentLanguage]["ts4y"], {cities: ""}) + this.props.massTransitRow[0].via[0]}</span> : "" }
						</span>
					</span>
					<Predictions rows={this.props.massTransitRow} localization={this.props.localization} timezone={this.props.screenData.timezone} />
				</div>
			</div>

			</Flipped>
		);
	}
}

function Predictions(props) {
	const {
		rows,
		localization,
		timezone,
	} = props;

	function Times() {
		return rows.map((transitTime, index) =>
            <span className="masstransit__row__prediction__vehicle" key={index}>
				{ transitTime.is_static === true ? <span className="masstransit__row__prediction__type">{localization.translations[localization.currentLanguage]["scheduled_abbr"]}</span> : null }
				<span className="content-block__row__prediction-number" key={index}>
					 <Moment unix format="h:mm" tz={timezone}>{transitTime.predictionTimestamp}</Moment>
					 <span className="content-block__row__prediction-ampm"><Moment unix format="a" tz={timezone}>{transitTime.predictionTimestamp}</Moment></span>
				</span>
			</span>
		);
	}

	return (
		<span className="masstransit__row__prediction">
			<Times />
		</span>
	);
}

MassTransitRow.displayName = "MassTransitRow";
export default MassTransitRow;
