import React, {
    Component
} from 'react';

import Weather from '../Weather/Weather.js';
import Moment from 'react-moment'; // https://momentjs.com/
import 'moment-timezone';

// https://css-tricks.com/​​avoiding-those-dang-cannot-read-property-of-undefined-errors/

// var options = {
//     timeZone: 'Europe/London',
//     year: 'numeric', month: 'numeric', day: 'numeric',
//     hour: 'numeric', minute: 'numeric', second: 'numeric',
// },

// formatter = new Intl.DateTimeFormat([], options)
// formatter.format(new Date())

class Clock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timeFormat: {
                minute: "numeric",
                hour: "numeric",
                timezone: null,
                month: "long",
                weekday: "short",
                day: 'numeric'
            },
            time: new Date(),
            weatherUnit: null,
            weather: [],
            hourFormat: this.props.screenConfig?.screen?.hour_format == 24 ? 'HH:mm' : 'hh:mm a',
        };
    }

    componentDidMount() {
        this.parseSettings();

        this.interval = setInterval(() => {
            this.setState({
                time: new Date().toISOString()
            });
        }, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    async parseSettings() {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.timeFormat.timezone = this.props.blockData.timezone;
        this.setState(stateCopy);

        // Importing locale package seems to be the only way to affect the Moment package
        const langCode = this.props.localization.currentLanguage;

        // Map TS Admin config/app.php l10n codes to available moment imports
        // 'en_us' => 'English (US)',
        // 'es_mx' => 'Spanish (Mexico)',
        // 'es_co' => 'Spanish (Columbia)',
        // 'fr_fr' => 'French (France)',
        // 'fr_ca' => 'French (Canada)',
        // 'de_de' => 'German (Germany)',
        // 'hi_in' => 'Hindi (India)',
        // 'it_it' => 'Italian (Italy)',
        // 'ja_jp' => 'Japanese (Japan)',
        // 'ko_kr' => 'Korean (Korea)',
        // 'pt_br' => 'Portguese (Brasil)',
        // 'sv_se' => 'Swedish (Sweden)',
        // 'zh_cn' => 'Chinese Simp. (China)',
        // 'zh_tw' => 'Chinese Trad. (Taiwan)',

        const packages = {
            // "en_us": "en", // There is no need to do an english package
            "fr_ca": "fr",
            "fr_fr": "fr",
            "ko_kr": "ko",
            "zh_cn": "zh-cn",
            "zh_tw": "zh-tw",
        };

        if (packages[langCode]) {
            // console.log(packages[langCode]);
            try {
                const packageCode = packages[langCode].toString();
                import('moment/locale/' + packageCode).then(() => {
                    this.setState({ trigger: true })
                });
            } catch (e) {
            }
        }

    }

    render() {
        // AngularJS used Java date format, convert to MomentJS
        let dateFormat = this.props.blockData.date_format || null;
        if (dateFormat) {
            dateFormat = dateFormat.replace('E', 'd');
        }

        // let hourFormat = parseInt(this.props.screenConfig.screen.hour_format);
        // console.log('hour format in Clock.js', this.state.hourFormat);
        // console.log(
		// 	'hour in Clock.js',
		// 	this.props.screenConfig?.screen.hour_format
		// );
        // console.log('the time is: ', this.state.time)
        return (
			<div className={this.props.cssClasses}>
				<div className='clock__row'>
					<span className='clock__row__clock-time'>
						<Moment
							date={this.state.time}
							format={this.state.hourFormat}
							tz={this.props.blockData.timezone}
							interval={0}
						/>
					</span>
					<span className='clock__row__clock-date'>
						<Moment
							locale={this.props.localization.currentLanguage.substring(
								0,
								2
							)}
							date={this.state.time}
							format={dateFormat || 'dddd, MMMM D'}
							interval={0}
						/>
					</span>
				</div>
				{this.props.blockData.weather_visible ? (
					<Weather
						type='clock'
						blockData={this.props.blockData}
						currentWeather={this.props.blockData.currentWeather}
						upcomingWeather={this.props.blockData.weather}
						localization={this.props.localization}
					/>
				) : null}
			</div>
		);
    }
}

Clock.displayName = "Clock";
export default Clock;
