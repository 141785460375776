import React, {
	Component
} from 'react';

import { ReactSVG } from 'react-svg';


// import lokalise from '../../localization/lokalise.helper';
// import { lang } from 'moment';

// This is just the Block Title and the Walk Min, see BlockHeader
class DocklessHeader extends Component {

	render() {

        let title = this.props.blockData.title || this.props.localization.translations[this.props.localization.currentLanguage]["ts3r"];

        if (!this.props.blockData.title && this.props.agencies[0].mode === 'pointtopoint') {
        	title = this.props.localization.translations[this.props.localization.currentLanguage]["ts3p"];
        }

        const columnHeader = this.props.localization.translations[this.props.localization.currentLanguage]["ts3b"];

        const min = this.props.localization.translations[this.props.localization.currentLanguage]["ts3i"];

		return (
			<h2 className="content-container__header dockless__header">
                <span className="dockless__header__title">{title}</span>
                <span className="dockless__header__min-header">{columnHeader} ({min})</span>
            </h2>
		);
	}
}
DocklessHeader.displayName = "DocklessHeader";
export default DocklessHeader;