import React, {
	Component
} from 'react';

import { ReactSVG } from 'react-svg';

import EMPTY from '../../img/transitscreen/crowding/empty.svg';
import NOT_CROWDED from '../../img/transitscreen/crowding/not_crowded.svg';
import SOMEWHAT_CROWDED from '../../img/transitscreen/crowding/somewhat_crowded.svg';
import CROWDED from '../../img/transitscreen/crowding/crowded.svg';

import afCrowdingIcon from '../../img/actionfigure/ui/crowding.svg';

const tsIcons = {
	"EMPTY": EMPTY,
	"NOT_CROWDED": NOT_CROWDED,
	"SOMEWHAT_CROWDED": SOMEWHAT_CROWDED,
	"CROWDED": CROWDED,
}

const afIcon = <ReactSVG className="crowding-icon visual-icon-fill" src={afCrowdingIcon} />;
const afIconOff = <ReactSVG className="crowding-icon empty" src={afCrowdingIcon} />;

const iconCounts = {
	"EMPTY": null,
	"NOT_CROWDED": [1, 2],
	"SOMEWHAT_CROWDED": [2, 1],
	"CROWDED": [3, 0],
}


function CrowdingIcon({ crowdingLevel }) {

	const iconCount = iconCounts[crowdingLevel];

	if (iconCount) {

		let iconOn = [];
		let iconOff = [];

		for (let i = 0; i < iconCount[0]; i++) {
			iconOn.push(<span key={i}>{afIcon}</span>);
		}

		for (let j = 0; j < iconCount[1]; j++) {
			iconOff.push(<span key={j}>{afIconOff}</span>);
		}

		return (
			<>
				{iconOn}
				{iconOff}
			</>
		)
	}
	return null;
}

function Crowding({ row }) {

	if (row && row.crowding) {
		return (
			<div className="Crowding">
				<CrowdingIcon crowdingLevel={row.crowding} />
			</div>
		)
	}

	return null;
}


export default Crowding;