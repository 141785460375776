import React, {
    Component
} from 'react';

import DockedRow from './DockedRow.js';
import DockedHeader from './DockedHeader.js';
import lokalise from '../../localization/lokalise.helper.js';

class Docked extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dockedRows: this.props.blockData.items,
            walkMinutes: null,
            rowLimit: this.props.blockData.row_limit,
            agencies: Object.values(this.props.blockData.agencies),
            agencyData: {
                modes: null,
                logo: Object.values(this.props.blockData.agencies)[0].logo,
                mode: Object.values(this.props.blockData.agencies)[0].mode,
                brandColor: Object.values(this.props.blockData.agencies)[0].brand_color,
                slug: Object.values(this.props.blockData.agencies)[0].slug
            }
        };

    }

    getIcons() {
        let icons = [];
        // agencyDataState = {...this.state.agencyData};

        // console.log('icons');
        // console.log(this.state.agencies);
        Object.values(this.props.blockData.agencies).map((agency, index) =>
            icons.includes(agency.mode) || icons.length === 0 ? icons.push(agency.mode) : ""
        );

        // agencyDataState.modes = icons;

        // this.setState({agencyDataState});
        // console.log(icons);
        // this.setState({
        //     agencyData: {
        //         modes: icons
        //     }
        // })
        return icons;
    }

    componentDidMount() {
        // this.parseSettings();
        // this.parseData();

    }

    parseSettings() {

        // this.setState({
        //     maxPredictions: 3, //this.props.blockData.max_predictions
        //     rowLimit: this.props.blockData.row_limit,
        //     agencies: this.props.blockData.agencies,
        //     agencyData: {
        //         logo: Object.values(this.props.blockData.agencies)[0].logo,
        //         mode: Object.values(this.props.blockData.agencies)[0].mode,
        //         brandColor: Object.values(this.props.blockData.agencies)[0].brand_color
        //     }
        // });
    }

    parseData() {
        this.setState({
            dockedRows: this.props.blockData.items,
        });
    }

    getRows() {
        const rowLimit = this.state.rowLimit === null ? 100 : this.state.rowLimit;

        if (this.state.agencyData.slug == "zipcar" && this.state.agencyData.slug == "enterprisecarshare") {
            return (
                <div className="error-message"><span className="">{this.props.localization.translations[this.props.localization.currentLanguage]["ts1d"]}</span></div>
            );
        }

        if (this.props.blockData.items.length === 0) {
            return (
                <div className="error-message"><span className="">{this.props.localization.translations[this.props.localization.currentLanguage]["ts1a"]}</span></div>
            );
        }

        if (this.props.blockData.items[0] && this.props.blockData.items[0].agencyInfo && this.props.blockData.items[0].agencyInfo.status === "closed") {
            return (
                <div className="error-message"><span className="">{this.props.localization.translations[this.props.localization.currentLanguage]["ts1b"]}</span></div>
            );
        }

        return this.props.blockData.items.slice(0, rowLimit).map((row, index) => {
            return (
                <DockedRow key={index} slug={this.state.agencyData.slug} localization={this.props.localization} agencies={this.state.agencies} agencyLogo={this.props.blockData.agencies[row.agency_id].logo} mode={row.mode} brandColor={this.state.agencyData.brandColor} agencyID={row.agency_id} vehicleCount={row.vehicle_count} spaceCount={row.space_count} dockedLocation={row.name} walkTime={row.walk_minutes} rowData={row} screenData={this.props.screenData} />
            )
        });
    }

    render() {

        let modes = this.getIcons();

        return (
            <div className={this.props.cssClasses + " " + this.state.agencyData.mode}>

                <DockedHeader {...this.props} {...this.state} />

                { this.getRows() }
            </div>
        );
    }
}
Docked.displayName = "Docked";
export default Docked;

