import { useEffect, useState } from 'react';

import { ReactSVG } from 'react-svg';

import afLight from '../img/actionfigure/logos/actionfigure-logo-on-light.svg';
import afDark from '../img/actionfigure/logos/actionfigure-logo-on-dark.svg';

const ActionFigureLogo = ({ screenData }) => {
    return (
        <div className="ActionFigureLogo">
            <img src={afLight} />
            <QRCode screenData={screenData} />
        </div>
    );
}

function QRCode({ screenData }) {

    const [qr, setQR] = useState(null);

    let _qr = null;

    useEffect(() => {
        if (screenData && screenData.device && screenData.device.app_link_qr) {
            const newQR = screenData.device.app_link_qr;
            if (_qr === null || _qr !== newQR) {
                _qr = newQR;
                setQR(newQR);
            }
        }
    });

    return (
        <div className="qr-code">
            {qr ? <div dangerouslySetInnerHTML={{ __html: qr }} /> : null}
        </div>
    )
}

export default ActionFigureLogo