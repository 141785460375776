import React, { useState, useEffect } from 'react';

import config from '../config';

const FooterSupport = ({ screenData }) => {

	const [emailAddress, setEmailAddress] = useState('support@actionfigure.ai');

	let _hash = null;

	useEffect(() => {
		let hash = screenData && screenData.device && screenData.device.hash ? screenData.device.hash : null;
		if (hash && hash !== _hash) {
			const supportEmail = `support+${hash}@actionfigure.ai`;
			setEmailAddress(supportEmail);
			_hash = hash;
		}
	});

	return (
		<div className="footer-support">
			Feedback? Contact <a href={`mailto:${emailAddress}`}>{emailAddress}</a> and let us know! v{config.version}
		</div>
	)
}

export default FooterSupport;