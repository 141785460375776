import React from 'react';

import ContentBlock from './ContentBlock.js';


function Column(props) {

    // Used to assign a "first child" and "last child" class 
    let observedGroupIds = [];

    return (
        <div className="screen-content__column">
            {props.columnData.map((block, index) => {

                let cssGroupClasses = '';

                if (block.block_group_id) {
                    cssGroupClasses = `content-block--group-${block.block_group_id}`;
                    if (observedGroupIds.indexOf(block.block_group_id) === -1) {
                        observedGroupIds.push(block.block_group_id);

                        cssGroupClasses += ' first-child';

                    } else if (observedGroupIds.length > 0) {
                        // If next block group id doesnt match, this is the last one
                        const nextBlockId = props.columnData[index + 1] && props.columnData[index + 1].block_group_id;

                        if (block.block_group_id !== nextBlockId) {
                            observedGroupIds.push(block.block_group_id);
                            cssGroupClasses += ' last-child';
                        }
                    }
                }
                return (
                    <ContentBlock cssGroupClasses={cssGroupClasses} key={index} index={index} blockData={block} screenData={props.screenData} alerts={props.alerts} localization={props.localization} />
                )
            }
            )}
        </div>

    )
}

Column.displayName = "Column";
export default Column;