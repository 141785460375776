import React from 'react';
import Column from './Column';

function ThemeBrampton(props) {

	const {
		state
	} = props;

	const {
		sidebarType,
		column_1,
		column_2,
		column_3,
		sidebar,
		alerts,
		localization,
		screenConfig,
	} = state;

	const {
		screen
	} = screenConfig;

	const {
		has_support,
		theme_color
	} = screen;

	return (
		<div className={"screen-wrapper " + sidebarType + " theme--" + theme_color + " theme--brampton"} >
			<div className="screen-wrapper__inner">
				<div className="screen-content">
					<div className="screen-content__main">
						{column_1 !== null ? <Column columnData={column_1} screenData={screen} alerts={alerts} localization={localization} /> : null}
						{column_2 !== null ? <Column columnData={column_2} screenData={screen} alerts={alerts} localization={localization} /> : null}
						{column_3 !== null ? <Column columnData={column_3} screenData={screen} alerts={alerts} localization={localization} /> : null}
					</div>
					{sidebar.length > 0 ? <div className="screen-content__sidebar">
						<Column columnData={sidebar} screenData={screen} localization={localization} />
					</div> : null}
				</div>
			</div>
			{
				has_support ? <div className="footer">Feedback? Contact <a href="mailto:support@transitscreen.com">support@transitscreen.com</a> and let us know!</div> : ""
			}
		</div>
	);
}

export default ThemeBrampton;
