import { useEffect, useState } from 'react';
import {
    Carousel
} from 'react-responsive-carousel';

import config from '../config';

const StockTicker = () => {

    useEffect(() => {
        getStocks();
    }, []);

    const [stocks, setStocks] = useState(null);

    async function getStocks() {
        if (Stocks) { // eslint-disable-line
            const stocks = new Stocks(config.alphaVantageAPIKey); // eslint-disable-line

            // var options = {
            //     symbol: 'AAPL',
            //     interval: 'daily',
            //     amount: 1,
            //     // start: new Date('2021-07-05'),
            //     // end: new Date('2021-07-07'),
            // };
            // const result = await stocks.timeSeries(options);
            // const yesterday = result[0].close;

            // console.log(result, resultToday);

            // console.log(yesterday, today);


            let ticker = [
                {
                    symbol: 'AAPL',
                    interval: 'daily',
                    amount: 1,
                    // start: new Date('2021-07-05'),
                    // end: new Date('2021-07-07'),
                },
                {
                    symbol: 'S&P',
                    interval: 'daily',
                    amount: 1,
                    // start: new Date('2021-07-05'),
                    // end: new Date('2021-07-07'),
                },
                {
                    symbol: 'TSLA',
                    interval: 'daily',
                    amount: 1,
                    // start: new Date('2021-07-05'),
                    // end: new Date('2021-07-07'),
                },
                {
                    symbol: 'MSFT',
                    interval: 'daily',
                    amount: 1,
                    // start: new Date('2021-07-05'),
                    // end: new Date('2021-07-07'),
                }
            ]

            let stockComp = [];


            ticker.forEach(async (tick) => {
                let result = await stocks.timeSeries(tick);

                // console.log(result);

                let lastClose = '';
                if (result && result[0]) {
                    lastClose = result[0].close;
                }

                let current = '';
                if (result && result[0]) {
                    current = result[0].open;
                }

                stockComp.push(<div key={tick.symbol} className="stock">
                    <p>{tick.symbol} {current}</p><br /><p className="last">Last {lastClose}</p>
                </div>);

            })


            setTimeout(() => {
                setStocks(stockComp);
            }, 1500);

        }
    }

    return (
        <div className="StockTicker">
            {stocks}
        </div>
    );
}

export default StockTicker