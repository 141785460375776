import React from 'react';


const url1 = "https://transitscreen.io/device/8gore";
const url2 = "https://transitscreen.io/device/5rjlp";
const duration = 10000;

// Paging test file, accessed when user visits https://.../paging
// Not used, for Sales testing

class Paging extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			frame1: true,
			frame2: false,
		}

		this.rotating = true;
		this.interval = null;
	}

	componentDidMount() {
		this.interval = setInterval(() => {
			if (this.rotating) {
				requestAnimationFrame(() => {
					this.setState({
						frame1: !this.state.frame1,
						frame2: !this.state.frame2,
					});
				})
			}
		}, duration);
	}

	componentDidCatch() {
		this.rotating = false;
		clearInterval(this.interval);
	}

	componentWillUnmount() {
		this.rotating = false;
		clearInterval(this.interval);
	}


	render() {
		const iframeStyle = {

			width: "100%",
			height: "100vh",
			margin: 0,
			padding: 0,
			border: 0,
			position: "absolute",
			top: 0,
			left: 0,
			background: "#f7f7f9",
			opacity: 1,
			transitionProperty: "opacity",
			transitionDuration: "750ms",
		}

		const iframe1 = {
			...iframeStyle,
			opacity: this.state.frame1 === true ? 1 : 0
		};
		const iframe2 = {
			...iframeStyle,
			opacity: this.state.frame2 === true ? 1 : 0
		};
		// console.log(this.state, iframe1.zIndex, iframe2.zIndex);
		return (
			<div>
				<iframe title={url1} style={iframe1} src={url1}></iframe>
				<iframe title={url2} style={iframe2} src={url2}></iframe>
			</div>
		)
	}

}

export default Paging;
