export default {
	internalAppName: 'transitscreen-react-frontend', // Sentry release reporting
	version: "7.4.0", // Sentry release reporting

	clientVersion: "0000077", // A tracker appended to the data call to track devices on the back-end

	alphaVantageAPIKey: "3RUQSR6LZXVL9MZJ", // Stock API @TODO Rip this out as part of Footer widget/stock options

	defaultUpdateCycleInterval: 45000,

	languageRotationInterval: 10000,

	maxMinutesPrediction: 60,

	localStorage: {
		screenConfigStore: '_tsScreenConfig',
	},

	defaultFlightPagingInterval: 10000

}
