import React, {
    Component
} from 'react';

import {
    Carousel
} from 'react-responsive-carousel';
import config from '../../config';

const FlightRow = (props) => {

    return (

        <div key={props.flightTime.faFlightId} className="flight__row content-container__row">
            <span className="flight__row__destination">{props.flightTime.destination || props.flightTime.origin}</span>
            <span className="flight__row__flight-info">
                <FlightIcon code={props.flightTime.airlineCode} />
                <span className="flight__row__call">
                    <span className="flight__row__flight-info__name">{props.flightTime.name}</span>
                    <span className="flight__row__flight-info__number">{props.flightTime.number}</span>
                </span>
            </span>

            <span className="flight__row__terminal-gate-info">
                <span className="flight__row__terminal-gate">
                    {props.flightTime.terminal !== null ? <span className="terminal-info">TERM {props.flightTime.terminal}</span> : null }
                    {props.flightTime.gate !== null ? <span className="gate-info">Gate {props.flightTime.gate}</span> : null }
                </span>
            </span>

            <span className="flight__row__departure">
                {props.flightTime.status === "delayed" ? <div className="original-time">{props.flightTime.scheduledDepartureTime || props.flightTime.scheduledArrivalTime}</div> : null}
                <div className="actual-time">
                    {props.flightTime.estimatedDepartureTime || props.flightTime.estimatedArrivalTime}
                </div>
            </span>

            <span className="flight__row__status">
                <span className={props.flightTime.status === "delayed" ? "delayed" : "on-time"}>{props.flightTime.status}</span>
            </span>
        </div>

    );
}

class FlightIcon extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: null
        }
    }
    componentDidMount() {

        if (this.props.code) {

            let url = "https://fe54981f5455028e4da4-91d59b46056b5806f131a0e9762d81ab.ssl.cf5.rackcdn.com/" + this.props.code + ".png";
            const img = new Image();
            img.src = url;
            img.onload = () => {
                this.setState({
                    url
                })
            };
        }
    }

    render() {
        if (this.state.url) {
            return (<img className="flight__row__flight-info__logo" src={this.state.url} />);
        }
        return (<svg className="flight__row__flight-info__logo" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><path fill="#CCCCCB" d="M13.2 14.7C20.3 25.9 33.4 31 40.3 42.4c-5.7 5-14.8 6.6-18.9 13.2-4.9-2.1-10.6-9.8-16.4-3.8 2.4 9.6 10 19.3 15.7 27.7 3.2 0 4.1-2.2 6.3-3.1.1-4.3-.2-8.2-1.3-11.3 8-2.5 14.5-6.5 21.4-10.1 7.5 8.4 5.9 26 12 35.9 3.4 1.5 6.9-1.8 9.4-3.1 1.1-14 .5-29.7.6-44.7 8-7.8 26.1-8.6 25.8-22.7-13.2-5.8-22.9 7-32.7 10.7C48.7 23.8 35.4 16.4 22 9c-4.2.6-6.8 2.8-8.8 5.7z"></path></svg>);
    }

}

class Flight extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let arrayItems = this.props.blockData.items,
            rowLimit = this.props.blockData.first_page_end,
            flightArr = Array.from({
                length: Math.ceil(arrayItems.length / rowLimit)
            }, (v, i) =>
                arrayItems.slice(i * rowLimit, i * rowLimit + rowLimit)
            );
        return (
            <div className={this.props.cssClasses}>

                {this.props.blockData.title ? <h2 className="content-container__header flight__header">{this.props.blockData.title}</h2> : null}
                {
                    arrayItems.length === 0 ?
                        <div className="content-container">
                            <span className="error-message">No departures</span>
                        </div> :
                        <div className="slide-wrap">
                            <Carousel autoPlay="true" infiniteLoop interval={this.props.blockData.paging_interval ?? config.defaultFlightPagingInterval} showThumbs={false}>
                                {
                                    flightArr.map((flightPage, index) =>
                                        <div key={index} className="slide">
                                            {
                                                flightPage.map((row, index) =>
                                                    <FlightRow key={row.faFlightId} index={index} pageEnd={this.props.blockData.first_page_end} flightTime={row} />
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </Carousel>
                        </div>
                }
            </div>
        );
    }
}

Carousel.displayName = "Carousel";
Flight.displayName = "Flight";
export default Flight;
