import React, {
    Component
} from 'react';

import {
    Flipped
} from "react-flip-toolkit";

class RowAlerts extends Component {

    render() {
        if (this.props.screenConfig && this.props.screenConfig.isScreenshot === true) return null;

        if (this.props.massTransitRow[0].alert_ids[0]) {
            return (
                <Flipped inverseFlipId={this.props.id} scale>
                    <div className="content-container__alert">
                    {
                        this.props.alertsArr.map((alert, index) => {

                            let alertDescription = alert.description;
                            if (alertDescription && alertDescription.length > 170) {
                                alertDescription = alertDescription.substring(0, 170) + '...';
                            }

                            if (alert.id === this.props.massTransitRow[0].alert_ids[0]) {
                                return (
                                    <div key={index} className="alert">
                                        <p>
                                            {alert.header}
                                            <span dangerouslySetInnerHTML={{__html: alertDescription }}></span>
                                        </p>
                                    </div>
                                );
                            }

                        })
                    }
                    </div>
                </Flipped>
            );
        }
        return null;
    }
}

RowAlerts.displayName = "RowAlerts";

export default RowAlerts;
