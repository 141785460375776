import React, {
    Component
} from 'react';

import ProgressArc from 'react-progress-arc';

class DockedRow extends Component {

    render() {
        // const container = document.createElement('div');
        // document.getElementsByTagName('body')[0].appendChild(container);
        // console.log(this.props.vehicleCount, this.props.spaceCount);

        let completedPercentage = 0;
        if (this.props.vehicleCount && this.props.vehicleCount !== 0) {
            completedPercentage = this.props.vehicleCount / (this.props.vehicleCount + this.props.spaceCount);
            completedPercentage = completedPercentage.toFixed(2);
        }

        let strokeColor = '#A5DF00';
        if (completedPercentage <= 0.3) {
            strokeColor = '#FD4A5C';
        } else if (completedPercentage > 0.3 && completedPercentage <= 0.5) {
            strokeColor = '#FF8C4B';
        }

        // Note the ProgressArc sizing is overriden by CSS so it can auto-resize with zoom
        // diameter and viewbox indicated is just a starting value
        return (
            <div className="content-container__row docked__row">

                <div className="docked__row__agency-logo" dangerouslySetInnerHTML={{ __html: this.props.agencyLogo }}></div>

                <div className="docked__row__docked-location">
                    <span className="docked__row__docked-location__name">{this.props.dockedLocation}</span>
                    <span className="docked__row__docked-location__vehicle-type-name">{this.props.rowData.vehicleModel}</span>
                </div>

                {!(this.props.spaceCount === 0 && this.props.vehicleCount === 1) ?
                    <div className="docked__row__vehicle-count">
                        <ProgressArc
                            completed={completedPercentage}
                            stroke={strokeColor}
                            background="#ccc"
                            strokeWidth="4"
                            diameter="45"
                            viewBox="0 0 45 45" />
                        <span>{this.props.vehicleCount}</span>
                    </div>
                    :
                    <div className="docked__row__vehicle-count"><span>{this.props.vehicleCount}</span></div>
                }

                <div className="docked__row__walk-time">
                    <span className="docked__row__walk-time__time content-container__row__prediction-number">
                        {this.props.walkTime}
                    </span>
                </div>

            </div>
        )
    }
}

DockedRow.displayName = "DockedRow";
export default DockedRow;
