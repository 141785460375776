import React from 'react';
import ScreenConfigHelper from '../ScreenConfig.helper.js';

import ContentBlock from './ContentBlock.js';

class Column extends React.Component {
	render() {

		const {
			columnData,
			screenConfig,
			alerts,
			localization,
			context,
		} = this.props;

		const {
			screen
		} = screenConfig;

		// Used to assign a "first child" and "last child" class 
		let observedGroupIds = [];

		return (
			<div className="screen-content__column">
				{columnData && columnData.map((block, index) => {

					let cssGroupClasses = '';

					if (block.block_group_id) {
						cssGroupClasses = `group-${block.block_group_id}`;
						if (observedGroupIds.indexOf(block.block_group_id) === -1) {
							observedGroupIds.push(block.block_group_id);
							cssGroupClasses += ' first-child';
						} else if (observedGroupIds.length > 0) {
							// If next block group id doesnt match, this is the last one
							const nextBlockId = columnData[index + 1] && columnData[index + 1].block_group_id;

							if (block.block_group_id !== nextBlockId) {
								observedGroupIds.push(block.block_group_id);
								cssGroupClasses += ' last-child';
							}
						}
					}

					return (
						<ContentBlock
							key={index}
							index={index}
							blockData={block}
							screenData={screen}
							screenConfig={screenConfig}
							cssGroupClasses={cssGroupClasses}
							alerts={alerts}
							localization={localization}
							context={context} />
					)
				})}
			</div>

		)
	}
}

Column.displayName = "Column";
export default Column;
