import React, {
    Component
} from 'react';

import DocklessHeader from './DocklessHeader.js';
import DocklessRow from './DocklessRow.js';
import lokalise from '../../localization/lokalise.helper.js';

class Dockless extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dockedRows: this.props.blockData.items,
            walkMinutes: null,
            rowLimit: this.props.blockData.row_limit,
            agencies: Object.values(this.props.blockData.agencies),
            agencyData: {
                modes: null,
                logo: Object.values(this.props.blockData.agencies)[0].logo,
                mode: Object.values(this.props.blockData.agencies)[0].mode,
                brandColor: Object.values(this.props.blockData.agencies)[0].brand_color
            },
        };
    }

    componentDidMount() {
        // this.parseSettings();
        // this.parseData();
    }

    getDocklessCounts() {
        const {
            blockData
        } = this.props;

        let docklessCountsByAgencyId = {};

        if (blockData && blockData.items && blockData.items.length > 1) {
            blockData.items.forEach((item) => {
                if (docklessCountsByAgencyId[item.agency_id] !== undefined) {
                    docklessCountsByAgencyId[item.agency_id] += 1;
                } else {
                    docklessCountsByAgencyId[item.agency_id] = 1;
                }
            })
        }
        return docklessCountsByAgencyId;
    }

    parseSettings() {

        // this.setState({
        //     maxPredictions: 3, //this.props.blockData.max_predictions
        //     rowLimit: this.props.blockData.row_limit,
        //     agencies: this.props.blockData.agencies,
        //     agencyData: {
        //         logo: Object.values(this.props.blockData.agencies)[0].logo,
        //         mode: Object.values(this.props.blockData.agencies)[0].mode,
        //         brandColor: Object.values(this.props.blockData.agencies)[0].brand_color
        //     }
        // });
    }

    parseData() {
        this.setState({
            dockedRows: this.props.blockData.items,
        });
    }

    getRows() {

        const docklessCountsByAgencyId = this.getDocklessCounts();

        const rowLimit = this.state.rowLimit === null ? 6 : this.state.rowLimit;

        // this.state.agencyData.mode === "pointtopoint" ?
        if (this.props.blockData.items.length === 0) {

            const listAgencies = () => {
                if (!this.props.blockData) return null;
                
                let logos = [];
                for (let i in this.props.blockData.agencies) {
                    let agency = this.props.blockData.agencies[i];
                    if (logos.indexOf(agency.logo) === -1) {
                        logos.push(agency.logo);
                    }
                }

                return logos.map((logo) => (
                    <div key={logo} className="agency-logo" dangerouslySetInnerHTML={{
                        __html: logo
                    }} />
                ));
            }

            return (
                <div className="error-message">
                    <span>{lokalise.getKey("ts6c")}</span>
                    {listAgencies()}
                </div>
            );
        }

        return (
            this.props.blockData.items.slice(0, rowLimit).map((row, index) =>
                <DocklessRow
                    key={index}
                    agencies={this.state.agencies}
                    agencyLogo={this.props.blockData.agencies[row.agency_id].logo}
                    mode={row.mode}
                    brandColor={this.state.agencyData.brandColor}
                    agencyID={row.agency_id}
                    vehicleCount={row.vehicle_count}
                    spaceCount={row.space_count}
                    dockedLocation={row.name}
                    walkTime={row.walk_minutes}
                    rowData={row}
                    docklessCountsByAgencyId={docklessCountsByAgencyId}
                    localization={this.props.localization}
                />
            )
        )
    }

    render() {

        return (
            <div className={this.props.cssClasses + " " + this.state.agencyData.mode}>

                <DocklessHeader {...this.props} {...this.state} />

                {this.getRows()}

            </div>
        );
    }
}
Dockless.displayName = "Dockless";
export default Dockless;
