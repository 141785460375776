import { css, Style } from 'react-css-in-js';

function ThemeStyle({ screenConfig, children }) {

    const {
        screen,
        themeName
    } = screenConfig;

    const {
        has_support,
        has_footer_widget,
        hide_outer_border,
        hide_row_lines,
        color_base_text,
        color_text_icon,
        color_background,
        color_outer_border,
        color_row_lines,
        background_image_url,
        theme_color,
        theme_id,
    } = screen;

    let transformClasses = '';
    transformClasses += hide_outer_border ? ' hide-outer-border' : '';
    transformClasses += hide_row_lines ? ' hide-row-lines' : '';

    let screenStyle = {};
    if (background_image_url) {
        screenStyle = { backgroundImage: `url("${background_image_url}")`, backgroundSize: 'cover', backgroundPosition: 'center center' };
        transformClasses += ' has-background-image';
    }

    if (has_footer_widget) {
        transformClasses += ' has-footer-widget';
    }

    let textColorStyling = null;
    if (color_base_text) {
        textColorStyling = (<Style>
            {css`
				body, .theme-style-wrapper, .AppStatus {
				    color: ${color_base_text} !important;
				}
			`}
        </Style>);
    }

    let iconStyling = null;
    if (color_text_icon) {

        iconStyling = (<Style>
            {css`
                .screen-logo svg * {
                    stroke: ${color_text_icon};
                }
                .screen-logo svg .t-fill {
                    fill: ${color_text_icon};
                }
                .building-directory__row__icon svg * {
                    fill: ${color_text_icon};
                    }
                .docked__row__vehicle-count span {
                    color: ${color_text_icon};
                    }
                ${'' /* .docked__row__vehicle-count svg circle:nth-child(2) {
                    stroke: ${color_text_icon};
                    } */}
                .masstransit__header__walk-minutes svg * {
                    fill: ${color_text_icon};
                    }
                .local-events__row__icon svg * {
                    fill: ${color_text_icon} !important;
                    }
                .wi:before {
                    color: ${color_text_icon};
                    }
                .slide-wrap .control-dots .dot {
                    background: ${color_text_icon};
                    }
                .flight__header, .local-events__header, .dockless__header__title, .docked__header__title, .building-directory__header, .masstransit__header__title {
                    color: ${color_text_icon};
                    }
                .docked__row__vehicle-type svg * {
                    stroke: ${color_text_icon};
                    }
                .visual-icon-fill svg * {
                    fill: ${color_text_icon} !important;
                    }
                .visual-icon-stroke svg * {
                    stroke: ${color_text_icon} !important;
                    }
            `}
        </Style>);
    }

    let outerBorderStyling = null;
    if (color_outer_border) {
        outerBorderStyling = (<Style>
            {css`
				.screen-content__column, .content-container, .screen-content__main, .screen-content__main:after {
					border-color: ${color_outer_border} !important;
				}
				.border-element .piece {
					background: ${color_outer_border} !important;
				}
			`}
        </Style>);
    }

    let rowBorderStyling = null;
    if (color_row_lines) {
        rowBorderStyling = (<Style>
            {css`
				.content-container__row, .content-container__header {
					border-color: ${color_row_lines} !important;
				}
			`}
        </Style>);
    }


    // @NOTE This option has some overrides in the style-color.scss sheets to create transparent bg for bg img
    let backgroundColor = null;
    if (color_background) {
        transformClasses += " has-background-color";
        backgroundColor = (<Style>
            {css`
				.theme-style-wrapper, .content-container__row, .screen-content__main,  .screen-content__main::after, .app-status {
					background-color: ${color_background} !important;
				}
			`}
        </Style>);
    }

    return (
        <div style={screenStyle} className={`theme-style-wrapper theme--${themeName} theme--${theme_color} ${transformClasses}`}>

            {textColorStyling}
            {iconStyling}
            {outerBorderStyling}
            {rowBorderStyling}
            {backgroundColor}

            {children}
        </div>
    )

}

export default ThemeStyle