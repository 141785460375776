import React from 'react';

// To add a new language, associate the l10n target key to the language file
// Sometimes for example we can re-use language files for multiple targets
// As you can see the language file names can be anything and we keep them
// capitalized the same as the Lokalise Export so that its easy to drag and drop updates
// The l10n key is what is set in TransitScreen Admin edit page (ie: fr_ca)
import de from './languages/de.json';
import en from './languages/en.json';
import en_us from './languages/en.json';
import es from './languages/es.json';
import es_co from './languages/es_CO.json';
import es_mx from './languages/es_MX.json';
import it from './languages/it.json';
import it_it from './languages/it.json';
import fr from './languages/fr.json';
import fr_fr from './languages/fr.json';
import fr_ca from './languages/fr_CA.json';
import pt from './languages/pt.json';
import tr from './languages/tr.json';
import zh_cn from './languages/zh_CN.json';
import zh_tw from './languages/zh_TW.json';
import ko_kr from './languages/ko.json';

// Then add the lang below in the translations table
// If React doesnt see it here, it will ignore it and show english as default
export default {
    translations: {
        de,
        en,
        en_us,
        es,
        es_co,
        es_mx,
        it,
        it_it,
        fr,
        fr_fr,
        fr_ca,
        pt,
        tr,
        zh_cn,
        zh_tw,
        ko_kr
    },

    defaultLanguage: 'en', // default fallback
    selectedLanguage: 'en', // initial

    /*
     * Returns the entire imported language set based on language code
     * Default returns English if we cant find it
     * @param language: required
     */
    getTranslations(language) {
        // console.log("GET?", language);
        if (language && this.translations[language.toLowerCase()]) {
            this.selectedLanguage = language.toLowerCase();
            return this.translations[language];
        }
        return this.translations[this.defaultLanguage];
    },

    /*
     * NOT USED - TS instead relies on the preloaded set above which gets passed as props
     * Gets the identified translation key and allows for value replacement
     * This is primarily used for complex translations
     * @param key: required "ts1a", "ts1b"....
     * @param language: required "en" "en_us" etc
     * @param params: optional {"key1":value1, "key2":value2...}
     * @param pluralKey: optional "one" or "other" to identify plural phrases
     * @return React Component Fragment or null
     */
    getKey(key, langCode, params, pluralKey) {

        const languageObject = this.translations[this.selectedLanguage];

        // Enable getting a different language if needed
        if (langCode && langCode !== null && this.translations[langCode]) {
            languageObject = this.translations[langCode];
        }

        // Return blank if key not found
        if (!languageObject[key]) return null;

        // console.log("Get translation", key, langCode, languageObject);

        var translation = languageObject[key];

        if (pluralKey && translation[pluralKey]) {
            translation = translation[pluralKey];
        }
        // console.log(translation, params);
        if (translation) {
            if (params && Object.keys(params).length > 0) {
                translation = this.replaceComponents(translation, params);
            }
            return translation;
        }
    },

    /*
     * Replaces phrases with full components via arrays ie "{minutes} min walk"
     * params can be both strings or HTML objects
     */
    replaceComponents(translation, params) {
        // console.log(translation, params);
        // let pieces = translation.split(" {");
        var regex = /\{(.*?)\}/g;
        var pieces = translation.split(regex).filter(Boolean);

        let compiled = pieces.map((piece) => {
            let key = piece.replace(/\{|\}/g, "");
            if (params[key]) {
                if (typeof params[key] === "string") {
                    // Param is a solid string, add a white space
                    return `${params[key]} `;
                } else {
                    // Params can include HTML in this case
                    return params[key];
                }
            }
            return `${piece} `;
        });
        // console.log("Compiled?", compiled);
        // Convert array into a series of fragments to avoid keys
        return compiled.map((comp, i) => {
            return <React.Fragment key={i}>{comp}</React.Fragment>
        })
    },

    /*
     * Replaces strings
     */
    replaceStrings(translation, params) {
        for (var property in params) {
            translation = translation.replace("{" + property + "}", params[property]);
        }
        return translation;
    },

    /*
     * NOT USED
     * Takes a phrase in one language and tries to return the current matching translation
     */
    translate(language, phrase) {
        // console.log("Get translation", phrase);
        var languageObject = this.translations[language];
        if (languageObject) {
            let key = phrase.toLowerCase();
            var translation = languageObject[key];
            if (translation) {
                return translation;
            }
        }
        return phrase;
    }
}
