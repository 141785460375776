import React from 'react';

import {
	ReactSVG
} from 'react-svg';

import Column from '../components/Column';
import ThemeStyle from '../components/ThemeStyle';

import FooterSupport from '../components/FooterSupport';

import tsIconLogo from '../img/transitscreen/t-icon-logo.svg';

function ThemeFutura({ state }) {

	const {
		column_1,
		column_2,
		column_3,
		sidebar,
		screenConfig,
		alerts,
		localization,
	} = state;

	const {
		screen,
		sidebarType,
		themeName,
	} = screenConfig;

	const {
		has_support,
		has_footer_widget,
		theme_color,
	} = screen;

	let columnCountClassName = column_1 && column_2 && column_3 ? 'three-column' : 'two-column';
	if (!column_2 && !column_3) columnCountClassName = 'one-column';

	return (
		<ThemeStyle screenConfig={screenConfig}>
			<div className={`screen-wrapper ${sidebarType}`}>

				<div className="screen-wrapper__inner">
					<div className="screen-content">
						<div className={`screen-content__main ${columnCountClassName}`}>
							<div className="screen-content__main__inner">
								{column_1 !== null ? <Column columnData={column_1} screenConfig={screenConfig} alerts={alerts} localization={localization} /> : null}
								{column_2 !== null ? <Column columnData={column_2} screenConfig={screenConfig} alerts={alerts} localization={localization} /> : null}
								{column_3 !== null ? <Column columnData={column_3} screenConfig={screenConfig} alerts={alerts} localization={localization} /> : null}
							</div>
							<div className="screen-content__footer">
								<div className="border-element border-element-left"><div className="piece"></div></div>
								<ReactSVG className="screen-logo" src={tsIconLogo} />
								<div className="border-element border-element-right"><div className="piece"></div></div>
							</div>
						</div>
						<div className="screen-content__sidebar">
							<Column columnData={sidebar} screenConfig={screenConfig} localization={localization} />
						</div>
					</div>
				</div>
				{has_support === true ? <FooterSupport screenConfig={screenConfig} /> : null}
			</div>
		</ThemeStyle>
	);
}

export default ThemeFutura;
