import React, {
    Component
} from 'react';

class StaticText extends Component {
    render() {
        return (
            <div className={this.props.cssClasses}>
                <div dangerouslySetInnerHTML={{ __html: this.props.blockData.text }} />
            </div>

        )
    }
}
StaticText.displayName = "StaticText";
export default StaticText;
