import React, {
    Component
} from 'react';

class Numina extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {}


    getChangeMessage(numinaSet, timePeriod) {
        // Prevent NaN
        if (numinaSet.previous.total.bicycle === 0) {
            return null
        }

        const change = (numinaSet.current.total.bicycle - numinaSet.previous.total.bicycle) / numinaSet.previous.total.bicycle * 100;
        const arrow = change > 0 ? '↑' : '↓';
        const arrowClass = change > 0 ? 'green' : '';

        let message = `${parseInt(change)}% over ${timePeriod}`;
        if (change === 0) {
            message = `Same as ${timePeriod}`;
        }
        return (
          <p><span className={`arrow-${arrowClass}`}>{arrow}</span> {message}</p>
        );
    }

    render() {

        const {
            blockData
        } = this.props;
        const {
            numina
        } = blockData;

        return (
            <div className={this.props.cssClasses}>

                <h3 className="header">All Bike Trips</h3>

                <div className="stats">

                    <p className="main"><span className="main-total">{numina.dayLevelMetrics.current.total.bicycle}</span> bike trips today</p>

                    {this.getChangeMessage(numina.dayLevelMetrics, 'yesterday')}

                    {this.getChangeMessage(numina.weekLevelMetrics, 'last week')}

                    {this.getChangeMessage(numina.monthLevelMetrics, 'last month')}

                </div>

            </div>
        );
    }
}

Numina.displayName = "Numina";
export default Numina;
