import 'moment-timezone';
import 'moment/locale/fr';
import 'moment/locale/es';

import React, { useEffect, useRef, useState } from 'react';

import Moment from 'react-moment'; // https://momentjs.com/

// https://github.com/erikflowers/weather-icons

const Weather = (props) => {
	const [state, setState] = useState({
		rowLimit:
			props.blockData.row_limit === null ? 4 : props.blockData.row_limit,
		weatherData: [],
	});
	const hourFormatState = useRef('ha');

	useEffect(() => {
		let weatherData = [];
		let shouldPreventWrapping = false; //if the weather item label is greater than 15 (i.e. "Possible Light Rain") the row is set to 3, instead of 4, to prevent page content to be pushed down

		if (props.type === 'clock') {
			weatherData.push(props.blockData.currentWeather);
		} else {
			weatherData.push(props.blockData.currentWeather);
			if (props.blockData.weather) {
				props.blockData.weather.forEach((weatherItem) => {
					if (weatherItem.label.length > 15) {
						shouldPreventWrapping = true;
					}
					weatherData.push(weatherItem);
				});
			}
		}
		setState({
			...state,
			rowLimit: shouldPreventWrapping ? 3 : state.rowLimit, //if the weather item label is greater than 15, rowLimit is set to 3
			weatherData: weatherData,
		});

	}, [props.blockData.currentWeather]);

	(function() {
		let hourFormat = props.screenConfig ? props.screenConfig.screen?.hour_format : "12";
		// console.log('hour format in the weather', hourFormat);
		return hourFormat === '24'
			? (hourFormatState.current = 'HH:00')
			: (hourFormatState.current = 'ha');
	})(); //IIFE

	return (
		<div className={props.cssClasses}>
			<div className='Weather'>
				{state.weatherData
					.slice(0, state.rowLimit)
					.map((weather, index) =>
						!weather ? (
							''
						) : (
							<div className='prediction' key={index}>
								<WeatherIcon
									weather={weather}
									screenConfig={props.screenConfig}
								/>
								<div className='details'>
									<span className={'time ' + index}>
										{index === 0 ? (
											props.localization.translations[
												props.localization
													.currentLanguage
											]['ts8z']
										) : (
											<Moment
												date={weather.timestamp}
												locale={props.localization.currentLanguage.substring(
													0,
													2
												)}
												format={hourFormatState.current}
												interval={0}
												tz={props.screenData.timezone}
											/>
										)}
									</span>
									<span className='temperature'>
										&nbsp;{weather.temperature}°
									</span>
								</div>
								<div className='label'>
									{
										props.localization.translations[
											props.localization.currentLanguage
										][weather.label.toLowerCase()]
									}
								</div>
							</div>
						)
					)}
			</div>
		</div>
	);
};

function WeatherIcon({ weather, screenConfig }) {
	const iconEle = useRef(null);

	function removeEle() {
		iconEle.current.display = 'none';
	}

	if (screenConfig && screenConfig.themeName === 'actionfigure') {
		const path =
			screenConfig.screen.theme_color === 'light'
				? 'weather-light'
				: 'weather-dark';

		return (
			<div className='weather-icon'>
				<img
					ref={iconEle}
					src={`/img/${path}/${weather.icon}.svg`}
					onError={removeEle}
					alt={weather.icon}
				/>
			</div>
		);
	}

	return <div className={'wi wi-forecast-io-' + weather.icon}></div>;
}

Weather.displayName = 'Weather';
export default Weather;
