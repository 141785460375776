import React, {
    Component,
    useState,
    useEffect
} from 'react';

import {
    ReactSVG
} from 'react-svg';

// Ceniques cannot reference local filepaths for some reason for img src, so may not work on them
// import afMobileIconOnLight from "../../img/actionfigure/logos/af-mobile-on-light.svg";
import afMobileIconOnDark from "../../img/actionfigure/logos/af-mobile-on-dark.svg";

function Greeting(props) {
    const hasQRCode = props.blockData.show_qr_code;
    return (
        <div className={props.cssClasses}>
            <div className="greeting__block">
                {hasQRCode ? <QRCode {...props} /> : <RegularCode {...props} />}
            </div>
        </div>
    );
}

function RegularCode(props) {

    function getCode() {
        let appCode = '';
        if (props.screenData && props.screenData.customer && props.screenData.customer.app_code) {
            appCode = props.screenData.customer.app_code;
        }
        return <div >{appCode}</div>
    }

    const icon = afMobileIconOnDark; // Bg of greeting is always a dark color

    return (
        <div className="regular-code-block">

            <div className="header">
                <div className="app-logo">
                    <img src={icon} />
                </div>
                <div className="text">
                    <b>Anytime. Anywhere.</b><br />
                    Every transportation option
                    together in just <u>one</u> app.
                </div>
            </div>

            <div className="content">
                <div className="text">
                    {props.localization.translations[props.localization.currentLanguage]["ts1c"]}
                </div>
                <div className="app-code">
                    {getCode()}
                </div>
            </div>

        </div>
    )
}

function QRCode(props) {

    const [qr, setQR] = useState(null);

    let _qr = null;

    useEffect(() => {
        let newQR = null;

        if (props.screenData && props.screenData.device) {
            newQR = props.screenData.device.app_link_qr;
        } else if (props.screenData && props.screenData.customer) {
            newQR = props.screenData.customer.app_link_qr;
        }

        if (_qr === null || _qr !== newQR) {
            _qr = newQR;
            setQR(newQR);
        }
    });

    // const icon = props.screenData.color_mode === 'light' ? afMobileIconOnDark : afMobileIconOnLight;
    const icon = afMobileIconOnDark; // Bg of greeting is always a dark color

    return (
        <div className="qr-code-block">
            <div className="qr-code">
                {qr ? <div dangerouslySetInnerHTML={{ __html: qr }} /> : null}
            </div>

            <div className="text">
                <img src={icon} className="app-logo" /><br />
                <b>Anytime. Anywhere.</b><br />
                Every transportation option
                together in just <u>one</u> app.
            </div>

        </div>
    )
}

Greeting.displayName = "Greeting";
export default Greeting;