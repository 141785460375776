import ActionFigureLogo from './ActionFigureLogo';
import NewsTicker from './NewsTicker';
import StockTicker from './StockTicker';

require('stocks.js');

const FooterWidget = ({screenData}) => {
    return (
        <div className="FooterWidget">
            <NewsTicker />
            <StockTicker />
            <ActionFigureLogo screenData={screenData} />
        </div>
    )
}

export default FooterWidget;