import React from 'react';

import lokalise from '../localization/lokalise.helper.js';

class AppStatus extends React.Component {

	constructor(props) {
		super(props);
	}

	getMessage() {
		const {
			appStatus,
			transitScreenDataEndpoint,
			refreshesSinceError,
		} = this.props;

		const appOnline = navigator.onLine;

		let message = "Loading...";

		if (appStatus === 'off') {
			message = "This Screen has been turned off, please contact support@actionfigure.ai for assistance."
		}

		if (appStatus == 'billingOff') {
			message = "This screen has been turned off. Please contact billing@actionfigure.ai for assistance.";
		}

		if (!transitScreenDataEndpoint) message = "Device ID is missing from URL.";

		if (appStatus === 'error') {
			if (appOnline) {
				if (refreshesSinceError <= 4) {
					message = "The Screen will be back momentarily. "
				} else if (refreshesSinceError > 4 && refreshesSinceError <= 6) {
					message = "The Screen is having a problem connecting."
				} else if (refreshesSinceError > 6) {
					message = "Please contact support@actionfigure.ai for assistance."
				}
			} else {
				if (refreshesSinceError > 1 && refreshesSinceError <= 6) {
					message = "The Screen is having trouble connecting. Please check the network connection."
				} else if (refreshesSinceError > 6) {
					message = "The Screen is unable to connect to network.  Please contact support@actionfigure.ai for assistance."
				}
			}
		} else if (appStatus === 'deployment') {
			message = "The Screen is deploying and will be back momentarily."
		}

		return message;
	}

	render() {

		const {
			appStatus,
			refreshesSinceError,
			firstLoad,
			screenConfig
		} = this.props;

		const {
			screen,
			sidebarType
		} = screenConfig;

		const {
			theme_id,
			color_background,
			theme_color,
		} = screen;

		// Be careful here, the logic of when to show the message is different from getMessage() itself
		if (appStatus === 'ready') return null;

		// First load errors must remain on the loading screen
		if (firstLoad === false && appStatus === 'error' && refreshesSinceError <= 1) {
			return null;
		}

		const backgroundColor = (theme_id === 3) && color_background ? { background: color_background } : null;

		return (
			<div id="appstatus" className={`AppStatus style--${theme_color} ${sidebarType}`} style={backgroundColor}>
				<p className="app-status__message">{this.getMessage()}</p>
			</div>
		)
	}
}

export default AppStatus;
