import React from 'react';

import BlockErrorHandler from './BlockErrorHandler.js';

/* Override imports */
import MassTransit from './blocks/MassTransit/MassTransit.js';

/* Parent imports */
import Messaging from '../blocks/Messaging/Messaging.js';
import Docked from '../blocks/Docked/Docked.js'
import Clock from '../blocks/Clock/Clock.js';
import Floating from '../blocks/Floating/Floating.js';
import Weather from '../blocks/Weather/Weather.js';
import Traffic from '../blocks/Traffic/Traffic.js';
import Logo from '../blocks/Logo/Logo.js';
import BuildingDirectory from '../blocks/BuildingDirectory/BuildingDirectory.js';
import Flight from '../blocks/Flight/Flight.js';
import Dockless from '../blocks/Dockless/Dockless.js';
import Greeting from '../blocks/Greeting/Greeting.js';
import LocalEvents from '../blocks/LocalEvents/LocalEvents.js';
import StaticText from '../blocks/StaticText/StaticText.js';
import StaticImage from '../blocks/StaticImage/StaticImage.js';

const screenBlocksComponents = {
    "masstransit": MassTransit,
    "community-message": Messaging,
    "docked": Docked,
    "clock": Clock,
    "floating": Floating,
    "weather": Weather,
    "traffic-mapbox": Traffic,
    "traffic": Traffic,
    "logo": Logo,
    "building-directory": BuildingDirectory,
    "flight": Flight,
    "dockless": Dockless,
    "greeting": Greeting,
    "local-events": LocalEvents,
    "static-text": StaticText,
    "image": StaticImage
};

class ContentBlock extends React.Component {
    render() {
        if (this.props.blockData.display_class) {
            const blockType = this.props.blockData.display_class;
            const ScreenBlocksComponent = screenBlocksComponents[this.props.blockData.display_class.replace(/\s+/g, '-')];

            if (blockType in screenBlocksComponents) { // if blockType created
                // console.log(this.props.blockData);
                let cssClasses = "content-block " + this.props.blockData.display_class + " " + this.props.cssGroupClasses;

                return (
                    <BlockErrorHandler>
                        <ScreenBlocksComponent
                            key={this.props.index}
                            index={this.props.index}
                            blockData={this.props.blockData}
                            screenData={this.props.screenData}
                            alerts={this.props.alerts}
                            cssClasses={cssClasses}
                            localization={this.props.localization} />
                    </BlockErrorHandler>
                )
            } else {
                return (
                    <div className="content-block">
                        <h2>{this.props.blockData.display_class}</h2>
                    </div>
                )
            }
        } else {
            console.log("no display class");
            console.log(this.props.blockData);
            return (
                <div></div>
            )
        }
    }
}

ContentBlock.displayName = "ContentBlock";

export default ContentBlock;
