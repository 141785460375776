import React, {
    Component
} from 'react';

import FloatingHeader from './FloatingHeader';
import FloatingRow from './FloatingRow';

class Floating extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logo: "",
            agencyName: "",
            agencyData: {
                logo: null,
                mode: null
            },
            agencies: [],
            floatingRows: [],
            rowLimit: 3,
            walkMinutes: null,
        };
    }

    componentDidMount() {
        this.parseSettings();
        this.parseData();
    }

    parseSettings() {

        // working on cleaning up
        let agencies = Object.values(this.props.blockData.agencies);
        // agencies.forEach(function(agency) {
        //     console.log(agency);
        // });
        let agencyID = Object.keys(this.props.blockData.agencies)[0];
        let agency = this.props.blockData.agencies[agencyID];

        this.setState({
            maxPredictions: 3, //this.props.blockData.max_predictions
            rowLimit: this.props.blockData.row_limit === null ? 6 : this.props.blockData.row_limit,
            logo: agency.logo,
            agencyName: agency.short_name,
            agencies: agencies,
            agencyData: {
                logo: Object.values(this.props.blockData.agencies)[0].logo,
                mode: Object.values(this.props.blockData.agencies)[0].mode,
                color: Object.values(this.props.blockData.agencies)[0].brand_color,
            }
        });
    }

    parseData() {

        this.setState({
            floatingRows: this.props.blockData.items,

        });
    }

    render() {

        return (
            <div className={this.props.cssClasses + " " + this.state.agencyData.mode}>

                <FloatingHeader {...this.props} {...this.state} />

                {this.props.blockData.items.slice(0, this.state.rowLimit).map((row, index) =>
                        <FloatingRow
                            key={index}
                            agencyLogo={this.props.blockData.agencies[row.agency_id].logo}
                            agencyID={row.agency_id}
                            agency={row.agency}
                            agencyColor={this.state.agencyData.color}
                            vehicleType={row.name}
                            prediction={row.prediction}
                            multiplier={row.pricing_multiplier}
                            localization={this.props.localization}  />
                )}
            </div>
        );
    }
}

Floating.displayName = "Floating";
export default Floating;