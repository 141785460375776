import React from 'react';
import * as Sentry from '@sentry/react';

import BlockHeader from './BlockHeader.js';

import MassTransit from '../blocks/MassTransit/MassTransit.js';
import Parking from '../blocks/Parking/Parking.js';
import Messaging from '../blocks/Messaging/Messaging.js';
import Docked from '../blocks/Docked/Docked.js'
import Clock from '../blocks/Clock/Clock.js';
import Floating from '../blocks/Floating/Floating.js';
import Weather from '../blocks/Weather/Weather.js';
import Traffic from '../blocks/Traffic/Traffic.js';
import TrafficV2 from '../blocks/Traffic/TrafficV2.js';
import Logo from '../blocks/Logo/Logo.js';
import BuildingDirectory from '../blocks/BuildingDirectory/BuildingDirectory.js';
import Flight from '../blocks/Flight/Flight.js';
import Dockless from '../blocks/Dockless/Dockless.js';
import Greeting from '../blocks/Greeting/Greeting.js';
import LocalEvents from '../blocks/LocalEvents/LocalEvents.js';
import StaticText from '../blocks/StaticText/StaticText.js';
import StaticImage from '../blocks/StaticImage/StaticImage.js';
import Numina from '../blocks/Numina/Numina.js';
import DisplayKit from '../blocks/DisplayKit/DisplayKit.js';
import TFLAlerts from '../blocks/TFLAlerts/TFLAlerts.js';
import MessagingV2 from '../blocks/MessagingV2/MessagingV2.js';

const screenBlocksComponents = {
    "masstransit": MassTransit,
    "community-message": Messaging,
    "docked": Docked,
    "clock": Clock,
    "floating": Floating,
    "weather": Weather,
    "traffic-mapbox": Traffic,
    "traffic": Traffic,
    "traffic-v2": TrafficV2,
    "logo": Logo,
    "building-directory": BuildingDirectory,
    "flight": Flight,
    "dockless": Dockless,
    "greeting": Greeting,
    "local-events": LocalEvents,
    "static-text": StaticText,
    "image": StaticImage,
    "numina": Numina,
    "parking": Parking,
    "display-kit": DisplayKit,
    "tfl-alerts": TFLAlerts,
    "messaging-v2": MessagingV2
};

function FallbackComponent() {
    return (
        <div className="ContentBlock">This block can't load right now.</div>
    )
}

function ContentBlock(props) {
    // console.log("PROPS", props);

    const { cssGroupClasses, blockData } = props;

    const { display_class } = blockData;

    if (props.blockData.display_class) {
        const blockType = props.blockData.display_class;
        const ScreenBlocksComponent = screenBlocksComponents[props.blockData.display_class.replace(/\s+/g, '-')];

        if (blockType in screenBlocksComponents) { // if blockType created
            return (
                <Sentry.ErrorBoundary fallback={FallbackComponent} >
                    <div className={`ContentBlock ${display_class} ${cssGroupClasses}`}>
                        <BlockHeader
                            blockData={props.blockData}
                            screenData={props.screenData}
                            screenConfig={props.screenConfig}
                            context={props.context}
                        />
                        <ScreenBlocksComponent
                            key={props.index}
                            index={props.index}
                            blockData={props.blockData}
                            screenData={props.screenData}
                            screenConfig={props.screenConfig}
                            cssClasses={'content-container'}
                            context={props.context}
                            alerts={props.alerts}
                            localization={props.localization} />
                    </div>
                </Sentry.ErrorBoundary>
            )
        } else {
            return (
                <div className="ContentBlock">
                    <b>{props.blockData.display_class}</b>
                </div>
            )
        }
    } else {
        console.log("no display class");
        console.log(props.blockData);
        return (
            <div></div>
        )
    }
}

ContentBlock.displayName = "ContentBlock";

export default ContentBlock;
