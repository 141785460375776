import React, {
    Component
} from 'react';

import { Carousel } from 'react-responsive-carousel';

class CommunityMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: 375, // Default for 3 cols
            height: 200,
        };
        this.wrapper = React.createRef();
        this.block = React.createRef();

        // Allow to specify fixed message slide number shown
        this.fixedSlideNumber = false;
        if (window.location.search) {
            const match = window.location.search.match(/[?&]messageSlide=[\w]+/g);
            if (match && match[0]) {
                const param = match[0].split('=');
                let slideNumber = parseFloat(param[1]);
                if (slideNumber >= 1) {
                    this.fixedSlideNumber = slideNumber;
                }
            }
        }
    }

    componentDidMount() {
        if (this.wrapper && this.wrapper.current) {
            const finalWidth = this.wrapper.current.clientWidth - 5; // Approx minus padding
            let finalHeight = Math.round(finalWidth * 0.66);

            // We have to also limit the height based on the screen resolution and padding
            const windowHeight = window.innerHeight - 150;

            if (finalHeight > windowHeight) {
                finalHeight = windowHeight;
            }

            this.setState({
                width: finalWidth,
                height: finalHeight
            }, () => {
                if (this.props.blockData.enable_testing) {
                    this.setState({
                        debug: {
                            actualLeft: Math.round(this.block.current.getBoundingClientRect().left),
                            actualTop: Math.round(this.block.current.getBoundingClientRect().top),
                            actualWidth: Math.round(this.block.current.getBoundingClientRect().width),
                            actualHeight: Math.round(this.block.current.getBoundingClientRect().height)
                        },
                        ...this.state
                    })
                }
            })
        }
    }

    render() {

        let messages = this.props.blockData.messages;

        if (this.fixedSlideNumber && [messages[this.fixedSlideNumber - 1]]) {
            messages = [messages[this.fixedSlideNumber - 1]];
        }

        return (
            <div className={this.props.cssClasses} ref={this.block}>

                <div className="slide-wrap" ref={this.wrapper}>
                    {this.props.blockData.enable_testing && this.state.debug ?
                        <div className="debug-overlay">
                            Actual Left: {this.state.debug.actualLeft}<br />
                            Actual Top: {this.state.debug.actualTop}<br /><br />
                            Actual Width: {this.state.debug.actualWidth}<br />
                            Actual Height: {this.state.debug.actualHeight}
                        </div>
                        : null}
                        
                    {
                        messages.length ?
                            <Carousel autoPlay infiniteLoop interval={10000} showThumbs={false}>
                                {messages.map((message, key) =>
                                    <Message key={key} message={message} height={this.state.height} themeName={this.props.screenData.themeName} />
                                )}
                            </Carousel>
                            :
                            null
                    }
                </div>
            </div>
        );
    }
}

function Message({ message, height, themeName }) {
    // Default message is just the thematic image
    if (themeName === 'actionfigure' && message.title === 'Messages coming soon') {
        message = { image: '/img/messaging/af-default.jpg' }
    }

    return (
        <div className={`community-message__row ${message.type} ${message.image ? 'has-image' : ''}`} style={{ height: height }}>
            {message.title ? <p className="community-message__row__title">{message.title}</p> : ""}
            {message.image ? <img alt={message.title} src={message.image} /> : ""}
            {message.message ? <p className="community-message__row__message">{message.message}</p> : ""}
        </div>
    )
}

Carousel.displayName = "Carousel";
CommunityMessage.displayName = "CommunityMessage";
export default CommunityMessage;
