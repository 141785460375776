import React from 'react';
import {
	ReactSVG
} from 'react-svg';

import AgencyLogo from '../components/AgencyLogo.js';

import bike from '../img/actionfigure/mode-icons/BikeShare 160px-01.svg';
import bus from '../img/actionfigure/mode-icons/Bus 160px.svg';
import car from '../img/actionfigure/mode-icons/Car 160px.svg';
import cableCar from '../img/actionfigure/mode-icons/Cable-car 160px.svg';
import dockless from '../img/actionfigure/mode-icons/Dockless-Bikeshare 160px.svg';
import ebike from '../img/actionfigure/mode-icons/eBikeshare 160px.svg';
import ferry from '../img/actionfigure/mode-icons/Ferry 160px.svg';
import flight from '../img/actionfigure/mode-icons/Flight 160px.svg';
import floating from '../img/actionfigure/mode-icons/Floating 160px.svg';
import funicular from '../img/actionfigure/mode-icons/Funicular 160px.svg';
import gondola from '../img/actionfigure/mode-icons/Gondola 160px.svg';
import highspeedrail from '../img/actionfigure/mode-icons/HighSpeed-Train 1 160px.svg';
import parking from '../img/actionfigure/mode-icons/Parking 160px.svg';
import pointToPoint from '../img/actionfigure/mode-icons/Point to Point 160px.svg';
import scooter from '../img/actionfigure/mode-icons/Scootershare 160px.svg';
import streetcar from '../img/actionfigure/mode-icons/Tram 160px.svg';
import shuttle from '../img/actionfigure/mode-icons/Bus 160px.svg';
import subway from '../img/actionfigure/mode-icons/Subway 160px.svg';
import taxicab from '../img/actionfigure/mode-icons/Taxi-Stand 160px.svg';
import train from '../img/actionfigure/mode-icons/Train 160px.svg';
import tram from '../img/actionfigure/mode-icons/Tram 160px.svg';

const MODE_ICONS = {
	bus: bus,
	subway: subway,
	train: train,
	'high-speed-rail': highspeedrail,
	tram: tram,
	streetcar: streetcar,
	shuttle: shuttle,
	'cable-car': cableCar,
	gondola,
	ferry: ferry,
	funicular: funicular,
	bikeshare: bike,
	ebikeshare: ebike,
	carshare: car,
	rideshare: floating,
	scootershare: scooter,
	'sit-scootershare': dockless,
	'dockless-bikeshare': bike,
	floating: floating,
	pointtopoint: pointToPoint,
	numina: bike,
	traffic: car,
	dockless: dockless,
	parking: parking,
	flight: flight,
};

function BlockHeader({ blockData, screenConfig }) {

	let agencies = null;
	if (blockData && blockData.agencies) {
		agencies = Object.values(blockData.agencies);
	}

	const dontShowAgencyLogos = ['dockless', 'floating'];

	const showAgencyLogos = blockData && dontShowAgencyLogos.indexOf(blockData.display_class) === -1;

	return (
		<div className="block-header align-right">
			<div className="border-element border-element-left"><div className="piece"></div></div>
			<div className="icons">
				{agencies && <ModeIcons blockData={blockData} agencies={agencies} />}
				{showAgencyLogos && agencies && <AgencyLogos agencies={agencies} />}
			</div>
			<div className="border-element border-element-right"><div className="piece"></div></div>
		</div>
	);
}

function ModeIcons({ blockData, agencies }) {

	let modeIcons = null;

	const modes = agencies.map((agency) => agency.mode);
	modeIcons = [...new Set(modes)]; // Uniquefy

	const traffic = ['traffic-v2', 'traffic'];
	if (traffic.indexOf(blockData.display_class) !== -1) {
		modeIcons = ['traffic'];
	}

	return modeIcons.map((modeName, index) => {
		if (!MODE_ICONS[modeName]) return null;
		return (
			<ReactSVG
				key={index}
				src={MODE_ICONS[modeName]}
				wrapper="span"
				className="mode-icon visual-icon-fill" />
		);
	});
}

function AgencyLogos({ agencies }) {

	let agencyLogos = agencies && agencies.map((agency) => (agency.logo));
	if (agencyLogos) {
		agencyLogos = [...new Set(agencyLogos)]; // Uniquefy
	}

	return (
		agencies && agencyLogos.map((logo, index) =>
			<AgencyLogo logo={logo} key={index} />
		)
	)
}

export default BlockHeader
