import {
    lang
} from 'moment';
import Moment from 'react-moment'; // https://momentjs.com/
import 'moment-timezone';

import RealTimeIcon from './RealTimeIcon.js';

import config from '../../config.js';

function Prediction({ row, lang, timezone }) {

    const firstVehicle = row[0]; // We let the first vehicle determine what template to use

    let useClockTime = false;
    if (firstVehicle.prediction >= config.maxMinutesPrediction) useClockTime = true;

    return (
        <span className={`Prediction ${useClockTime ? 'Prediction--ClockTime' : ''}`}>

            {firstVehicle.predictionType === 'waittime' ?
                <span className="WaitTimeText" dangerouslySetInnerHTML={{ __html: firstVehicle.prediction }} />
                : null}

            {firstVehicle.predictionType !== 'waittime' && <TimePrediction row={row} timezone={timezone} useClockTime={useClockTime} />}

        </span>
    )
}

function TimePrediction({ row, timezone, useClockTime }) {
    /* Avoid "33 (min), 11:15am" so only show clock time for BOTH if the first prediction exceeds the maxMinutesPrediction */
    return row.map((vehicle, index) => {
        return <span key={index} className="TimePrediction">
            {index === 0 && !useClockTime ? <MinutesTime index={index} vehicle={vehicle} /> : null}
            {index === 0 && useClockTime ? <ClockTime vehicle={vehicle} timezone={timezone} /> : null}

            {index > 0 ? <span className="separator">{", "}</span> : null}

            {index > 0 && !useClockTime ? <MinutesTime index={index} vehicle={vehicle} /> : null}
            {index > 0 && useClockTime ? <ClockTime vehicle={vehicle} timezone={timezone} /> : null}

            <RealTimeIcon vehicle={vehicle} />
        </span>
})
}

function MinutesTime({ index, vehicle }) {
    return (
        <span className="MinutesTime">
            {vehicle.prediction === 0 ? "<1" : vehicle.prediction}
        </span>
    )
}

function ClockTime({ vehicle, timezone }) {
    return (
        <span className="ClockTime">
            {<Moment add={{ minutes: vehicle.prediction }} format="h:mm a" tz={timezone} />}
        </span>
    )
}

export default Prediction