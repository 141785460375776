import React, {
    Component
} from 'react';


class Traffic extends Component {

    constructor(props) {
        super(props);

        this.state = {

            rowLimit: null,
            walkMinutes: null,
            // agencies: [],
            agencyData: {
                logo: null,
                mode: null
            }
        };
    }

    componentDidMount() {
        this.parseSettings();
        // this.parseData();
    }

    parseSettings() {
        this.setState({
            maxPredictions: 3, //this.props.blockData.max_predictions
            rowLimit: this.props.blockData.row_limit,
            agencyData: {
                logo: Object.values(this.props.blockData.agencies)[0].logo,
                mode: Object.values(this.props.blockData.agencies)[0].mode
            }
        });
    }

    parseData() {
        this.setState({
            dockedRows: this.props.blockData.items,
        });
    }

    /*
        TODO:
            * add inrix logo
            * add route background image

    */

    render() {
        return (
            <div className={this.props.cssClasses + " " + this.state.agencyData.mode}>

                <h2 className="content-container__header traffic__header">Traffic travel times</h2>
                <div className="traffic__table">
                    <div className="content-container__row traffic__row traffic__row__header content-container__row__header" >

                            <span className="traffic__row__route">{this.props.localization.translations[this.props.localization.currentLanguage]["ts8x"].toLowerCase()}</span>
                            <span className="traffic__row__destination">drive time to</span>
                            <span className="traffic__row__prediction"><span>{this.props.localization.translations[this.props.localization.currentLanguage]["ts8z"].toLowerCase()}</span></span>

                    </div>
                    {this.props.blockData.routes.map((route, key) =>
                        <div key={key} className="content-container__row traffic__row" >
                            <span className={"traffic__row__route " + route.route_class_name}>{route.route_number}</span>
                            <span className="traffic__row__destination">{route.destination_name}</span>
                            <span className="traffic__row__prediction">
                                <span>{route.predictions[0]}</span><span className="traffic__row__prediction__unit">min</span>
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

Traffic.displayName = "Traffic";
export default Traffic;
