import React, {
    Component
} from 'react';
import Moment from 'react-moment'; // https://momentjs.com/
import {
    Carousel
} from 'react-responsive-carousel';

class BuildingDirectory extends Component {
    render() {
        // Assign places into page arrays
        let placesArr = [];
        this.props.blockData.places.forEach((place) => {
            const pos = place.pageNumber - 1;
            if (placesArr[pos]) {
                placesArr[pos].push(place);
            } else {
                placesArr[pos] = [place];
            }
        });

        // console.log(placesArr);

        return (
            <div className={this.props.cssClasses}>

                {
                    this.props.blockData.title !== "" ? <h2 className="content-container__header building-directory__header">{this.props.blockData.title || this.props.localization.translations[this.props.localization.currentLanguage]["ts1g"]}</h2> : ""
                }
                {
                    this.props.blockData.places.length === 0 ?
                        <div className="error-message"><span className="">Directory coming soon.</span></div>
                        :
                        <div className="slide-wrap">
                            <Carousel autoPlay infiniteLoop interval={10000} showThumbs={false}>
                                {
                                    placesArr.map((placesPage, index) =>
                                        <div key={index} className="slide">
                                            {
                                                placesPage.map((row, index) =>
                                                    <Places key={index} place={row} localization={this.props.localization} />
                                                )
                                            }
                                        </div>
                                    )
                                }
                            </Carousel>
                        </div>
                }
            </div>
        );
    }
}

const Places = (props) => {
    // console.log(props);
    return (
        <div className="content-container__row building-directory__row">
            <span className="building-directory__row__icon visual-icon-fill" dangerouslySetInnerHTML={{ __html: props.place.image || props.place.icon }}></span>
            <span className="building-directory__row__location-name">
                <span>{props.place.name}</span>
                {props.place.closeTime ? <span className="building-directory__row__location-name__close-time">Open until <Moment format="h a">{props.place.closeTime[0]}</Moment></span> : ""}
            </span>
            <span className="building-directory__row__location">
                <span className="building-directory__row__location__specific">{props.place.location}</span>
                {props.place.travelMode === "walking" && props.place.travelMinutes ? <span className="building-directory__row__location__distance">{props.place.travelMinutes} {props.localization.translations[props.localization.currentLanguage]["ts3j"]}</span> : ""}
                {props.place.travelMode === "cycling" && props.place.travelMinutes ? <span className="building-directory__row__location__distance">{props.place.travelMinutes} {props.localization.translations[props.localization.currentLanguage]["ts9d"]}</span> : ""}
                {props.place.travelMode === "driving" && props.place.travelMinutes ? <span className="building-directory__row__location__distance">{props.place.travelMinutes} {props.localization.translations[props.localization.currentLanguage]["ts7d"]}</span> : ""}
            </span>
        </div>
    );
}

Carousel.displayName = "Carousel";
BuildingDirectory.displayName = "BuildingDirectory";
export default BuildingDirectory;
