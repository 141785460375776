import React from 'react';

const TFLAlerts = (props) => {
    const { blockData, cssClasses, localization } = props;
    const lang = localization.translations[localization.currentLanguage];

    const { alerts, title } = blockData;
    const { agency, items } = alerts;

    console.log(props.blockData);

    return (
        <div className={cssClasses}>
            <Header agency={agency} title={title} />
            <Rows items={items} />
        </div>
    )
}

const Header = ({ agency, title }) => {
    return (
        <header className="header">
            <div className="logo" dangerouslySetInnerHTML={{ __html: agency.logo }}></div>
            <h2 className="title">{title}</h2>
        </header>
    )
}

const Rows = ({ items }) => {
    let goodService = [];
    let alerts = [];

    items.forEach((item) => {
        if (item.effect.toUpperCase() === "GOOD SERVICE") {
            goodService.push(item);
        } else {
            alerts.push(item);
        }
    });

    alerts = alerts.sort((a, b) => {
        var nameA = a.effect.toUpperCase(); // ignore upper and lowercase
        var nameB = b.effect.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    const customizedItems = [
        ...alerts,
        { type: "GOOD SERVICE", items: goodService }
    ];

    return customizedItems.map((item) => {

        if (item.type === "GOOD SERVICE") {
            return <GoodService item={item} />
        }

        return (
            <div className="row">
                <div className="route" style={{ backgroundColor: item.routeColor, color: item.textColor }}>
                    <div className="route-name">{item.typeName}</div>
                </div>
                <div className="effect">
                    {item.effect}
                </div>
            </div>
        )
    })
}


const GoodService = ({ item }) => {
    return (
        <div className="row">
            <div className="route">
                {item.items.map((line) => (
                    <div className="linebox" style={{ backgroundColor: line.routeColor, color: line.textColor }} />
                ))}
            </div>
            <div className="effect">
                Good Service
            </div>
        </div>
    )
}

export default TFLAlerts