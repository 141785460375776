import React from 'react';

import AppStatus from './components/AppStatus';
import ThemeFutura from './ThemeFutura/ThemeFutura';
import ThemeBrampton from './ThemeBrampton/ThemeBrampton';
import ThemeActionFigure from './ThemeActionFigure/ThemeActionFigure';
import ThemeOrangeBarrel from './ThemeOrangeBarrel/ThemeOrangeBarrel';
import ScreenConfig from './ScreenConfig.helper';
import Updater from './Updater';

import config from './config';

// import mockdata from './mockdata/devtest.json';

import lokalise from './localization/lokalise.helper.js';

let translationInterval = null;

class App extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			refreshesSinceError: 0,
			timeoutDuration: config.defaultUpdateCycleInterval,

			transitScreenDataEndpoint: null,
			credentialOption: 'same-origin', // Generally the default credential for TS Admin

			// 1-1 from TS Backend
			alerts: [],
			column_1: [],
			column_2: [],
			column_3: [],
			sidebar: [],

			// Server call /device/config/hash.json
			screenConfig: {
				themeName: null,
				isScreenshot: null,
				sidebarType: 'thin-sidebar',
				screen: {}, // Comes from server
				device: {}, // Comes from server
			},

			// Control mechanisms
			refreshesSinceError: 0,

			// Localization control
			localization: {
				currentLanguage: 'en',
				languages: [],
				translations: {}
			},

			// Used to toggle status information
			appStatus: 'loading', // loading, error, ready
			firstLoad: true,

		};

		this.client_version = config.clientVersion;
		this.updateTime = null; // Tracker for 8 hour refresh
		this.localizationWasSet = false;

		this.handleDataSuccess = this.handleDataSuccess.bind(this);
		this.handleDataError = this.handleDataError.bind(this);
	}

	async componentDidMount() {
		// return this.updateData(mockdata.data); // Mock testing
		const urlPath = window.location.pathname;

		// Stop if no path is given
		if (urlPath === '/') return null;

		// Try to read the URL path
		const endpoint = this.getTransitScreenEndpoint(urlPath);
		// console.log("What is endpoint", endpoint);
		if (endpoint.action === 'redirect') {
			window.location.replace(endpoint.url);
		} else if (endpoint.action === 'success') {
			const screenConfig = await ScreenConfig.getScreenConfigData(endpoint.configUrl, endpoint.credentialOption, this.state.screenConfig);
			this.setState({
				screenConfig: screenConfig,
				transitScreenDataEndpoint: endpoint.url, // This will trigger Updater to start working
				credentialOption: endpoint.credentialOption,
			});

			// App was successful to load, fade IN this component (App) and fade out Debug
			// Debug is separate from React due to older Android devices
			const appDiv = window.document.getElementById("app");
			const debugDiv = window.document.getElementById("debug");
			if (appDiv && appDiv.style && debugDiv && debugDiv.style) {
				window.requestAnimationFrame(() => {
					appDiv.style.opacity = 1;
					debugDiv.style.display = 'none';
				});
			}
		} else if (endpoint.action === 'error') {
			// This means the dataID was not obtained in the URL path
		}
	}

	componentDidCatch(error, errorInfo) {
	}

	componentWillUnmount() {
	}

	getTransitScreenEndpoint(urlPath) {
		// Validate URL to obtain dataID

		// Remove everything after ?= params
		const validate = urlPath ? urlPath.split('?')[0] : '';
		// This strictly matches /screens/view/deviceid or /device/view/deviceid
		let match = /^(\/(screens\/view|device)\/)(\w){1,32}(\/)?$/.exec(validate);

		if (!match) {
			// We wont accept this URL, redirect to blank
			return {
				action: 'redirect',
				url: '/'
			}
		}

		// Otherwise we accept this input and proceed to ingest data ID for TS

		// Default public /device/{dataID} endpoint
		let dataID = match[0].replace('/device/', '');
		let cakeControllerPath = 'device';
		let credentialOption = 'same-origin';

		// Auth only /screens/view/{screenID} endpoint
		if (match[2] === 'screens/view') {
			dataID = match[0].replace('/screens/view/', '');
			cakeControllerPath = 'screens';
			credentialOption = 'include';
		}

		// Screenshot URL
		if (urlPath.indexOf('imageclient') !== -1 || urlPath.indexOf('.png') !== -1) {
			dataID = match[0].replace('.png', '');
		}

		// In Production, allow the domain to use the same URL to do the data call which gets
		// routed internally via NGINX -- Ceniques do not allow cross-origin calls
		let transitScreenDataDomain = '';

		// Attempt to force https on the data call if we can sniff the hostname
		if (window.location.host) {
			transitScreenDataDomain = `https://${window.location.host}`;
		}

		// Localhost can directly call transitscreen.io OR staging.transitscreen.io for /device/ links only
		// For all use cases Localhost does NOT work /screens/view
		if (window.location.hostname === 'localhost') {
			transitScreenDataDomain = 'https://transitscreen.io';
		}

		// Allow use of Staging backend -- again Ceniques will not allow this
		const searchQuery = window.location.search || document.location.search;
		const queryParams = new URLSearchParams(searchQuery);
		// The frontend-staging.transitscreen.io proxy only works for /device/ links not /screens/view
		if (queryParams.has('staging')) {
			transitScreenDataDomain = 'https://staging.transitscreen.io';
		} else if (queryParams.has('localhost')) {
			transitScreenDataDomain = 'https://transitscreen';
		}

		const transitScreenDataEndpoint = transitScreenDataDomain + '/' + cakeControllerPath + '/data/' + dataID + '.json?version=React&client_version=' + this.client_version;

		const tsConfigEndpoint = transitScreenDataDomain + '/' + cakeControllerPath + '/config/' + dataID + '.json';

		// Construct data call URL
		if (dataID) {
			return {
				action: 'success',
				credentialOption,
				url: transitScreenDataEndpoint,
				configUrl: tsConfigEndpoint
			}
		}
		return {
			action: 'error'
		};
	}


	// Database refresh trigger of page with attempted cache reset
	async refreshPage() {
		if ('caches' in window) {
			caches.keys().then((names) => {
				names.forEach(async (name) => {
					await caches.delete(name)
				})
			})
		}
		window.location.reload();
	}


	/*
	 * Parses out language codes and "rotates" the language on the page if there are multiple langs
	 * @param languages - String value of lang codes from TS Admin "en_us" or "en_us, fr_ca, lang_code, etc"
	 */
	setupLocalization(screen) {

		// console.log("SETUP?", screen);

		if (this.localizationWasSet) return null;

		let languageCodes = this.state.localization.currentLanguage;
		// Override with screen?
		if (screen && screen.l10n) {
			languageCodes = screen.l10n;
		}

		let languageArr = languageCodes.split(", "),
			languageObj = {};

		languageArr.map((language, index) => {
			languageObj[language] = lokalise.getTranslations(languageArr[index]);
		});

		let newLocalization = {
			...this.state.localization
		};
		newLocalization.currentLanguage = languageArr[0];
		newLocalization.languages = languageArr;
		newLocalization.translations = languageObj;

		this.setState({
			localization: newLocalization
		});

		// Only rotate if there are more than one
		if (this.state.localization.languages.length > 1) {
			let langIndex = -1;
			translationInterval = setInterval(() => {
				langIndex++;
				if (langIndex >= this.state.localization.languages.length) {
					langIndex = 0;
				}
				let localization = {
					...this.state.localization
				};
				localization.currentLanguage = this.state.localization.languages[langIndex];

				this.setState({
					localization: localization
				})
				document.documentElement.lang = this.state.localization.currentLanguage;
			}, config.languageRotationInterval);
		}

		this.localizationWasSet = true;
	}

	/*
	 * A valid data response requires some housekeeping before we update the screen
	 * Including clearing out old errors or just refreshing the page
	 * @param response - Server response that was successfully jsonified
	 */
	handleDataSuccess(json) {
		// JSON is prevalidated for structure in Updater.js
		if (!json) return null;

		if (json.data.screen && json.data.screen.status === 'off') {
			this.turnOffScreen();
		} else if (json.message && json.message.includes('This screen has been turned off')) {
			this.turnOffScreen();
		} else if (!this.state.firstLoad && json.data.screen.version && this.state.screenConfig.screen.version && json.data.screen.version !== this.state.screenConfig.screen.version) {
			// Trigger screen refresh via database only after first load in order to prevent unlimited loops for bad state data
			// console.log("VERSIONS", json.data.screen.version, this.state.screenConfig.screen.version);
			this.refreshPage();
		} else {
			// Set initial load time
			if (this.updateTime === null) {
				this.updateTime = Date.now();
			} else if (Date.now() > this.updateTime + (26 * 60 * 60 * 1000) + Math.floor(Math.random() * 60 * 60 * 1000)) {
				// Hard refresh page every 26-27 hours. Math.random() ensures that
				// all screens do not refresh at the same time.
				this.refreshPage();
			}

			// this.writeToScreenConsole("Update success");
			this.updateData(json.data);
		}
	}

	turnOffScreen() {
		this.setState({
			appStatus: "off"
		})
	}

	/*
	 * Updates screen with valid json both blocks and screen
	 * @param Validated `data` object containing required props
	 */
	updateData(data) {

		const newScreenConfig = this.getUpdatedScreenConfigData(data);

		// Data parsing ======================================================

		let data_column_1 = [],
			data_column_2 = [],
			data_column_3 = [],
			data_sidebar = [],
			data_alerts = [];

		// console.log(response);

		data.columns[1] ? data_column_1 = data.columns[1] : data_column_1 = null;
		data.columns[2] ? data_column_2 = data.columns[2] : data_column_2 = null;
		data.columns[3] ? data_column_3 = data.columns[3] : data_column_3 = null;
		data.columns[4] ? data_sidebar = data.columns[4] : data_sidebar = [];

		data.alerts ? data_alerts = data.alerts : data_alerts = [];

		this.setState({
			appStatus: 'ready',
			column_1: data_column_1,
			column_2: data_column_2,
			column_3: data_column_3,
			sidebar: data_sidebar,
			alerts: data_alerts,
			screenConfig: newScreenConfig,

			// Clear all Error States (extremely important)
			firstLoad: false,
			refreshesSinceError: 0,
			timeoutDuration: config.defaultUpdateCycleInterval
		});

	}

	/*
	 * Updates screen with valid screen object, sets up any UI changes based on TS Admin specs
	 * @TODO Move this into screen config
	 * @param Validated `screen` object containing required props
	 */
	getUpdatedScreenConfigData(newData) {

		const newScreen = newData.screen;

		if (document.title === "TransitScreen" && newScreen.pretty_name) {
			document.title = "TransitScreen - " + newScreen.pretty_name;
		}

		this.setupLocalization(newScreen);

		const updatedScreenConfigData = ScreenConfig.updateScreenConfigData(this.state.screenConfig, newScreen, newData.columns);

		return {
			...this.state.screenConfig,
			...updatedScreenConfigData
		};
	}

	handleDataError(error) {
		// Error retry and backoff schedule
		// This does not necessarily match up with what we show in AppStatus.js
		let timeoutDuration = config.defaultUpdateCycleInterval;
		let appStatus = 'error';
		if (error.includes('deployment')) {
			appStatus = 'deployment';
		} else if (error.includes('billing')) {
			appStatus = 'billingOff';
		} else if (error.includes('off')) {
			appStatus = 'off';
		}

		if (this.state.refreshesSinceError === 1) {
			// The first attempt is a quick re-ping
			timeoutDuration = 15000;
		} else if (this.state.refreshesSinceError > 1 && this.state.refreshesSinceError <= 4) {
			// Next three slowly back off
			timeoutDuration = 60000;
		} else {
			// Then go to extended back off
			timeoutDuration = 120000;
		}

		this.setState({
			refreshesSinceError: this.state.refreshesSinceError + 1,
			appStatus,
			timeoutDuration
		});

		// console.log("GetData Failed", error, this.state.refreshesSinceError);
		// this.writeToScreenConsole(error);
	}

	// This method is used to write errors to the front-end for Ceniques which have no console
	// Add a div to index.html with id of errors and position absolute to allow this to show up
	// writeToScreenConsole(message) {
	// 	const ele = document.getElementById('errors');
	// 	ele.innerHTML = ele.innerHTML + '<br />' + message;
	// }

	render() {

		const {
			transitScreenDataEndpoint,
			timeoutDuration,
			credentialOption,
			refreshesSinceError,
			appStatus,
			firstLoad,
			screenConfig
		} = this.state;

		const {
			themeName,
		} = screenConfig;

		let themeComponent = null;
		if (themeName) {
			if (themeName === 'futura') {
				themeComponent = <ThemeFutura state={this.state} />;
			} else if (themeName === 'brampton') {
				themeComponent = <ThemeBrampton state={this.state} />
			} else if (themeName === 'actionfigure') {
				themeComponent = <ThemeActionFigure state={this.state} />
			} else if (themeName === 'orangebarrel') {
				themeComponent = <ThemeOrangeBarrel state={this.state} />
			}
		}

		// console.log("screenConfig", screenConfig, themeComponent);

		// 2.2 is simply an arbitrary base multiplier to match previous Angular zoom scaling
		const screenFontSize = this.state.screenConfig.screen.zoom ? this.state.screenConfig.screen.zoom * 2.2 : 2.2;

		return (
			<div className={`App ${appStatus} ${refreshesSinceError > 1 ? 'recovery' : ''}`} id="app" style={{ fontSize: `${screenFontSize}em`, opacity: 0 }}>
				<AppStatus
					transitScreenDataEndpoint={transitScreenDataEndpoint}
					refreshesSinceError={refreshesSinceError}
					appStatus={appStatus}
					firstLoad={firstLoad}
					screenConfig={screenConfig}
				/>
				<Updater
					timeoutDuration={timeoutDuration}
					transitScreenDataEndpoint={transitScreenDataEndpoint}
					credentialOption={credentialOption}
					handleDataSuccess={this.handleDataSuccess}
					handleDataError={this.handleDataError}
				/>
				{themeName && themeComponent}
			</div>
		);
	}
}

App.displayName = "App";
export default App;
