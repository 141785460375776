import React, {
    Component
} from 'react';

import {
    ReactSVG
} from 'react-svg'

// Map the TS Database mode names to icons
import bike from '../../img/transitscreen/mode-icons/bike.svg';
import car from '../../img/transitscreen/mode-icons/car.svg';
import ebike from '../../img/transitscreen/mode-icons/ebike.svg';
import scooter from '../../img/transitscreen/mode-icons/scooter.svg';
import moped from '../../img/transitscreen/mode-icons/moped.svg';

import lokalise from '../../localization/lokalise.helper.js';

// Icons for the mode of transit
const MODE_ICONS = {
    bikeshare: bike,
    "dockless-bikeshare": bike,
    ebikeshare: ebike,
    pointtopoint: car,
    scootershare: scooter,
    "sit-scootershare": moped
}

class DocklessRow extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {

        const {
            docklessCountsByAgencyId,
            agencyID,
            mode,
        } = this.props;

        let vehicleName = null;
        let numberOfVehicles = null;

        if (docklessCountsByAgencyId && docklessCountsByAgencyId[agencyID]) {
            numberOfVehicles = docklessCountsByAgencyId[agencyID];

            let modeTranslation = "ts4h"; // scootershare
            if (mode === "dockless-bikeshare") {
                modeTranslation = "ts4i";
            } else if (mode === "bikeshare") {
                modeTranslation = "ts4i";
            } else if (mode === "carshare") {
                modeTranslation = "ts4g";
            }

            vehicleName = numberOfVehicles === 1 ? lokalise.getKey(modeTranslation, null, null, "one") : lokalise.getKey(modeTranslation, null, null, "other");
        }

        return (
            <div className="content-container__row docked__row dockless__row">
                <div className="docked__row__agency-logo dockless__row__agency-logo" dangerouslySetInnerHTML={{ __html: this.props.agencyLogo }}></div>
                <div className="docked__row__docked-location">
                    <span className="docked__row__docked-location__name">{this.props.dockedLocation}</span>
                    <span className="docked__row__docked-location__vehicle-type-name">{this.props.rowData.vehicleModel}</span>

                    <span className="docked__row__docked-location__vehicle-count">
                    </span>

                </div>
                <div className="docked__row__vehicle-type dockless__row__vehicle-type">{MODE_ICONS[this.props.mode] ? <ReactSVG className="visual-icon-stroke" src={MODE_ICONS[this.props.mode]} /> : null}</div>
                <div className="docked__row__walk-time">
                    <span className="docked__row__walk-time__time content-container__row__prediction-number">
                        {this.props.walkTime}
                    </span>
                </div>
            </div>
        )
    }
}

DocklessRow.displayName = "DocklessRow";
export default DocklessRow;
