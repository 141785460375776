import React, {} from 'react';

import { ReactSVG } from 'react-svg';

const FloatingRow = (props) => {
    const style = {
        "borderColor": props.agencyColor
    };

    let multiplier = props.multiplier > 1 ? parseFloat(props.multiplier).toFixed(1) : false;

    return (
        <div className="content-container__row floating__row">
            <span className="floating__row__vehicle-logo" dangerouslySetInnerHTML={{ __html: props.agencyLogo }}></span>
            <span className="floating__row__vehicle-type">{props.vehicleType}</span>
            <span className="floating__row__surge-multiplier" >{multiplier ? <span style={style} className="floating__row__surge-multiplier__inner">{multiplier + "x"}</span> : ""}</span>
            <span className="floating__row__prediction">
                <span className="floating__row__prediction__time content-container__row__prediction-number">{props.prediction} </span>
            </span>
        </div>
    );
}

export default FloatingRow