import React from 'react';

function AgencyAlerts(props) {
    const {
        screenData,
        visibleAlerts,
        agencyAlertIds
    } = props;

    function getAgencyAlerts() {
        return agencyAlertIds.map((agencyalert, index) => {
            return visibleAlerts.map((alert, index) => {
                if (alert.id === agencyalert) {
                    return (
                        <div key={index} className="content-container__alert">
                            <span className="content-container__alert__header">{alert.header}</span>
                            {alert.description ? <span className="content-container__alert__description" dangerouslySetInnerHTML={{ __html: alert.description.substring(0, 100) }}></span> : null}
                        </div>
                    )
                }
                return null;
            });
        });
    }

    if (!screenData.isScreenshot && agencyAlertIds && agencyAlertIds.length > 0 && visibleAlerts && visibleAlerts.length > 0) {
        return (
            <div className="masstransit-agency-alerts">
                {getAgencyAlerts()}
            </div>
        );
    }
    return null;
}

export default AgencyAlerts;
