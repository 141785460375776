import config from './config';

const ScreenConfigHelper = {
    /*
    * This is just for first load to do some UI pre-checks
    * @return IT MUST ALWAYS PASS BACK AN {}
    */
    getScreenConfigData: async (configUrl, credentialOption, currentScreenConfig) => {

        let screenConfig = {};
        let themeName = "";
        let isScreenshot = false;

        const newScreenConfigData = await getLocalData(configUrl, credentialOption);

        if (newScreenConfigData) {
            screenConfig = newScreenConfigData;
            themeName = getThemeName(newScreenConfigData.screen);
        }

        // Screenshots disable certain elements later
        const urlPath = window.location.pathname;
        if (urlPath.indexOf('imageclient') !== -1 || urlPath.indexOf('.png') !== -1) {
            isScreenshot = true;
        }

        return {
            ...currentScreenConfig,
            ...screenConfig,
            themeName: themeName,
            isScreenshot: isScreenshot,
        }
    },

    updateScreenConfigData: (screenConfig, screen, columns) => {
        let newScreenData = screenConfig;
        newScreenData.screen = screen;
        newScreenData.customer = screen.customer;
        newScreenData.device = screen.device;

        let themeName = getThemeName(screen);
        newScreenData.themeName = themeName;

        // Temporary for fixing column_layout, remove later
        const cols = Object.keys(columns);
        const hasSidebar = cols.indexOf('4') > -1;
        const hasThree = cols.indexOf('3') > -1;
        const hasTwo = cols.indexOf('2') > -1;
        const hasOne = cols.indexOf('1') > -1;

        const sidebarType = getSidebarType(columns);

        newScreenData.columnLayout = (hasThree && 'three-column') || (hasTwo && 'two-column') || (hasOne && 'one-column');
        newScreenData.hasSidebar = hasSidebar;
        newScreenData.sidebarType = sidebarType;

        if (localStorage) {
            setLocalStorage(newScreenData);
        }

        return newScreenData;
    }
}

export default ScreenConfigHelper;

/*
* Handles all retrieval and saving of screen config data
* @NOTE only use localStorage if it exists, older browsers, cenique, etc may not allow it
* @return resolve with object or false
*/
async function getLocalData(configUrl, credentialOption) {
    return new Promise((resolve, reject) => {

        // We never let this call freeze the screen
        setTimeout(() => { resolve(false) }, 1000);

        if (localStorage && localStorage.getItem(config.localStorage.screenConfigStore)) {
            const existingData = localStorage.getItem(config.localStorage.screenConfigStore);
            try {
                const json = JSON.parse(existingData);

                const existingConfigData = json[document.URL];

                // Update config for next reload
                getConfig(configUrl, credentialOption).then((response) => {
                    if (response.success) {
                        setLocalStorage(response.json, existingConfigData);
                        // In this case, there is existing data but it was wrong
                        if (!existingConfigData) {
                            resolve(response.json);
                        }
                    }
                });

                if (existingConfigData) {
                    resolve(existingConfigData);
                }
            } catch (e) {
                // @TODO Report to airbrake 
                console.error(e);
                resolve(false);
            }
        } else {
            getConfig(configUrl, credentialOption).then((response) => {
                if (response.success) {
                    if (localStorage) {
                        setLocalStorage(response.json);
                    }
                    resolve(response.json);
                } else {
                    resolve(false);
                }
            });
        }
    });
}

function setLocalStorage(configData, existingConfigData) {
    let storeJson = {};
    if (existingConfigData) {
        storeJson[document.URL] = {
            ...existingConfigData,
            ...configData
        };
    } else {
        storeJson[document.URL] = configData;
    }
    localStorage.setItem(config.localStorage.screenConfigStore, JSON.stringify(storeJson));
}

// Map theme ids to easy to use names and only ingest theme ids recognized here
function getThemeName(screen) {
    let themeName = 'futura'; // aka theme_id === 1
    if (screen && screen.theme_id === 2) {
        themeName = 'brampton';
    } else if (screen && screen.theme_id === 3) {
        themeName = 'futura'; // futura handles 'premium' features without need of this id anymore
    } else if (screen && screen.theme_id === 4) {
        themeName = 'actionfigure';
    } else if (screen && screen.theme_id === 5) {
        themeName = 'orangebarrel';
    }
    return themeName;
}

function getSidebarType(columns) {

    const sidebarBlocks = columns && columns[4];

    let sidebarType = "thin-sidebar";
    if (!sidebarBlocks || sidebarBlocks.length === 0) {
        sidebarType = "no-sidebar";
    } else {
        for (const sidebarBlock of sidebarBlocks) {
            if (sidebarBlock.display_class === "building-directory" || sidebarBlock.display_class === "local-events" || sidebarBlock.display_class === "community-message" || sidebarBlock.display_class === "tfl-alerts") {
                sidebarType = "wide-sidebar";
                break;
            } else {
                sidebarType = "thin-sidebar";
            }
        }
    }
    return sidebarType;
}

function getConfig(url, credentialOption) {
    return window.fetch(url, {
        method: 'GET',
        cache: 'no-store',
        mode: 'cors',
        redirect: 'follow',
        credentials: credentialOption,
    }).then((response) => {
        return response.json();
    }).then((json) => {
        return { success: true, json };
    }).catch((error) => {
        return { success: false, error };
    });
}
