import { ReactSVG } from 'react-svg';
import rtIcon from '../../img/actionfigure/ui/realtime.svg';

function RealTimeIcon({ vehicle }) {
    if (vehicle && vehicle.is_static === false) {
        return <ReactSVG className="RealTimeIcon visual-icon-fill" src={rtIcon} />
    }
    return null;
}

export default RealTimeIcon