import React from 'react';

function AgencyLogo(props) {
	if (!props.logo) return null
	return (
		<span className="agency-logo" dangerouslySetInnerHTML={{ __html: props.logo }}></span>
	)
}

export default AgencyLogo;
