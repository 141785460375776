import React from 'react';

// import { ReactSVG } from 'react-svg';

function DockedHeader(props) {

	const title = props.blockData.title || props.agencies[0].short_name;

	const columnHeader = props.localization.translations[props.localization.currentLanguage]["ts3b"];

	const min = props.localization.translations[props.localization.currentLanguage]["ts3i"];

	return (
		<h2 className="content-container__header docked__header">
			<span className="docked__header__title">{title}</span>
			<span className="docked__header__min-header">{columnHeader} ({min})</span>
		</h2>
	)
}
DockedHeader.displayName = "DockedHeader";

export default DockedHeader;