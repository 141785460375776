import React from 'react';

import BlockHeaderTS from '../ThemeFutura/BlockHeader.js';
import BlockHeaderAF from '../ThemeActionFigure/BlockHeader.js';
import BlockHeaderB from '../ThemeBrampton/BlockHeader.js';

function BlockHeader({ blockData, screenConfig }) {

	const {
		themeName
	} = screenConfig;

	let ele = <BlockHeaderTS blockData={blockData} screenConfig={screenConfig} />;

	if (themeName) {
		if (themeName === 'actionfigure') {
			ele = <BlockHeaderAF blockData={blockData} screenConfig={screenConfig} />;
		} else if (themeName === 'brampton') {
			ele = <BlockHeaderB blockData={blockData} screenConfig={screenConfig} />;
		}
	}

	return ele;
}

export default BlockHeader
