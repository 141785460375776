import { useEffect } from 'react';

// Exposed updateInterval ID for unit testing purposes
let updateInterval = null; // As a global this will also prevent any duplicate components from running
let setTimeoutDuration = null;

let isDebug = window.location.search.match(/debug/g);
let debugInfo = {};

/*
 * @param transitScreenDataEndpoint - Endpoint to be used to retrieve TS data
 * @param credentialOption - fetch credentials
 * @param timeoutDuration - Attenuates the setInterval rate
 * @param handleDataSuccess - Called only for successfully parsed JSON object
 * @param handleDataError - Handles any error even in a success call
 * @param unitTesting - boolean flag to expose updateInterval ID
 */
const Updater = ({ transitScreenDataEndpoint, credentialOption, timeoutDuration, handleDataSuccess, handleDataError }) => {

    /*
     * Sets up interval only listening to transitScreenDataEndpoint changes
     */
    useEffect(() => {
        // console.log("useEffect", updateInterval, transitScreenDataEndpoint, credentialOption, timeoutDuration);
        if (!updateInterval && transitScreenDataEndpoint && credentialOption && timeoutDuration && handleDataSuccess && handleDataError) {
            // First page load
            getData();
            setUpdaterCycle();
            return () => {
                clearInterval(updateInterval);
                updateInterval = null; // Important for unit testing
            }
        }
    }, [transitScreenDataEndpoint]);

    useEffect(() => {
        if (updateInterval) {
            if (setTimeoutDuration !== timeoutDuration) {
                // New timeout duration, reset interval
                setUpdaterCycle();
            }
        }
    }, [timeoutDuration]);

    function setUpdaterCycle() {
        // console.log("setUpdaterCycle duration", timeoutDuration);
        if (updateInterval) clearInterval(updateInterval);
        updateInterval = setInterval(() => {
            getData();
        }, timeoutDuration);
        setTimeoutDuration = timeoutDuration;
    }

    /*
     * Only concerned with relaying proper error or success to frontend    
     */
    function getData() {
        const newDate = new Date();
        if (!debugInfo.firstCall) {
            debugInfo.firstCall = newDate;
        }
        // console.log("getData", newDate.getTime());
        if (debugInfo.lastCallTime) {
            debugInfo.sinceLastCall = Math.abs(newDate.getTime() - debugInfo.lastCallTime.getTime());
        }
        if (debugInfo.lastResponseTime) {
            debugInfo.sinceLastResponse = Math.abs(newDate.getTime() - debugInfo.lastResponseTime.getTime());
        }
        debugInfo.lastCallTime = newDate;
        debugInfo.numberCalls = (debugInfo.numberCalls || 0) + 1;

        // get the data
        window.fetch(transitScreenDataEndpoint, {
            method: 'GET',
            cache: 'no-store',
            mode: 'cors',
            redirect: 'follow',
            credentials: credentialOption,
        }).then((response) => {
            // console.log("Response", response);
            const newDate = new Date();
            debugInfo.lastResponseTime = newDate;
            if (response.status === 200) {
                response.json().then((json) => {
                    if (json && json.data && json.data.columns && json.data.screen) {
                        handleDataSuccess(json);
                    } else {
                        handleDataError("JSON Empty or Invalid");
                    }
                });
            } else if (response.status === 503) {
                // Cloudflare deployment
                handleDataError("Deployment");
            } else if (response.status === 401) {
                // @TODO Capture Unauth vs Screen Exceptions
                try {
                    response.json().then((json) => {
                        handleDataError(json.message);
                    });
                } catch (e) {
                    handleDataError("Screen is off");
                }
            } else {
                // console.error(response);
                handleDataError(`Response Error ${response.status}`);
            }
        }).catch((error) => {
            const newDate = new Date();
            debugInfo.lastResponseTime = newDate;
            console.error("Catch", error);
            handleDataError(`Catch Error ${JSON.stringify(error)}`);
        });
    }

    if (isDebug) {
        return <div style={{ textAlign: 'right', position: 'absolute', bottom: 0, right: 0, zIndex: 100, background: "#000", color: "#fff", padding: 10, fontSize: "0.7em" }}>
            Update Interval Id: {updateInterval}<br />
            Attempted Duration: {timeoutDuration} ms<br />
            Since Last Call: {debugInfo.sinceLastCall} ms<br />
            Since Last Response: {debugInfo.sinceLastResponse} ms<br />
            Number of Calls: {debugInfo.numberCalls} <br /><br />

            Last Call: {debugInfo.lastCallTime && debugInfo.lastCallTime.toUTCString()}<br />
            Last Response: {debugInfo.lastResponseTime && debugInfo.lastResponseTime.toUTCString()}<br />
            Started: {debugInfo.firstCall && debugInfo.firstCall.toUTCString()}<br />
            TS Link: {transitScreenDataEndpoint}

            <br />
            <br />
        </div>
    }

    return null;
}

export default Updater