import React, {
    Component
} from 'react';
// import lokalise from '../../localization/lokalise.helper';
// import { lang } from 'moment';

class MassTransitHeader extends Component {
    
    render() {
        return (
            <h2 className="content-block__header masstransit__header">
                <span className="masstransit__header__title">{this.props.blockData.title}</span>
                {this.props.blockData.walk_minutes !== null ? <span className="masstransit__header__walk-minutes"> {this.props.blockData.walk_minutes} {this.props.localization.translations[this.props.localization.currentLanguage]["ts3j"]}</span> : ""}
            </h2>
        );
    }
}
MassTransitHeader.displayName = "MassTransitHeader";
export default MassTransitHeader;
