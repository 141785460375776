import React, {
	Component
} from 'react';

import { ReactSVG } from 'react-svg';

import walkingIcon from '../../img/actionfigure/ui/walking.svg';

// import lokalise from '../../localization/lokalise.helper';
// import { lang } from 'moment';

// This is just the Block Title and the Walk Min, see BlockHeader
class MassTransitHeader extends Component {


	render() {

		const isStaticTranslation = this.props.unfilteredRow && this.props.unfilteredRow[0] && this.props.unfilteredRow[0].is_static === true ? 'ts3l' : 'ts3c';

		return (
			<h2 className="content-container__header masstransit__header">
                <span className="masstransit__header__title">{this.props.blockData.title}</span>
                <span className="masstransit__header__walk-minutes">
                { this.props.blockData.walk_minutes !== null ? 
                	<span className="wrapper"><ReactSVG className="icon" src={walkingIcon} /><span>{this.props.blockData.walk_minutes}</span> {this.props.localization.translations[this.props.localization.currentLanguage]["ts3i"]}</span> 
                	: ""}
            	</span>
                <span className="masstransit__header__min-header">{this.props.localization.translations[this.props.localization.currentLanguage][isStaticTranslation]} ({this.props.localization.translations[this.props.localization.currentLanguage]["ts3i"]})</span>
            </h2>
		);
	}
}
MassTransitHeader.displayName = "MassTransitHeader";
export default MassTransitHeader;