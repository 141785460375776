import { useEffect, useState, useRef } from 'react';
import { setSourceMapRange } from 'typescript';

const DisplayKit = ({ blockData }) => {

    const dk = useRef(null);

    const [hasComponent, setComponent] = useState(null);

    const {
        position_left,
        position_top,
        block_height,
        block_width,
        enable_testing
    } = blockData;

    let actualLeft = "";
    let actualTop = "";
    let actualWidth = "";
    let actualHeight = "";
    useEffect(() => {
        if (dk && dk.current && !hasComponent) {
            setComponent(dk.current);
        }
    });
    actualLeft = hasComponent && Math.round(hasComponent.getBoundingClientRect().left);
    actualTop = hasComponent && Math.round(hasComponent.getBoundingClientRect().top);
    actualWidth = hasComponent && Math.round(hasComponent.getBoundingClientRect().width);
    actualHeight = hasComponent && Math.round(hasComponent.getBoundingClientRect().height);
    // console.log(blockData);

    return (
        <div className="DisplayKit" style={{ padding: "0 1em 2em 1em", fontSize: "0.8em" }}>

            <div ref={dk} style={{ border: enable_testing && "2px dashed #222", height: block_height && block_height + "px" || undefined }}>

                {enable_testing ? <>
                    Actual Left: {actualLeft}<br />
                    Actual Top: {actualTop}<br /><br />

                    Actual Width: {actualWidth}<br />
                    Actual Height: {actualHeight}<br /><br />
                </> : null}

            </div>

        </div>
    );
}

export default DisplayKit;