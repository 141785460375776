const CONSTANTS = require('./constants.js');

module.exports = {

    /*
     * Returns filtered array of vehicles for front-end JS-based apps
     * @param vehicles array - accepts any kind of items array with numeric or text predictions
     * @param options object - filtering options
     *  predictionType string - "minutesAway"
     *  groupVehicles bool - true
     *  filterHeadways bool - true
     *  maximumPrediction integer - 0
     *  minimumPrediction integer - 15
     *  walkMinutes integer - 6
     *  walkTimeMultiplier integer decimal - 0.7
     *  rowLimit integer - 3
     * @return rows array - array of arrays or flat array
     */
    getVehicleRows: function(vehicles, options) {
        const module = this;

        var rows = module._getVehicleGroups(vehicles, options);

        if (!options.groupVehicles) {
            rows = module._flattenVehicleGroups(rows);
        }

        // 5. Row limit is applied last
        if (options.rowLimit && options.rowLimit >= 0) rows = rows.slice(0, options.rowLimit);

        return rows
    },

    /*
     * Applies all the TS filtering logic
     */
    _getVehicleGroups(vehicles, options) {
        const module = this;

        var vehicleGroups = {};
        vehicles.forEach(function(vehicle) {
            // 1-2. Min max and walk min
            if (options.predictionType === "minutesAway" && options && Object.keys(options).length > 0) {
                vehicle = module._applyVehicleFilterOptions(vehicle, options);
            }

            // 3. Group vehicles
            if (vehicle) {
                const groupingKey = options.groupingKey ? options.groupingKey : CONSTANTS.groupingKey;
                const groupId = vehicle[groupingKey];
                if (vehicleGroups[groupId]) {
                    vehicleGroups[groupId].push(vehicle);
                } else {
                    vehicleGroups[groupId] = [vehicle];
                }
            }
        });

        // Must unwrap the object into an array
        vehicleGroups = Object.keys(vehicleGroups).map(groupId => vehicleGroups[groupId]);

        // 4. Close headways
        if (options.predictionType === "minutesAway" && options.filterHeadways === true) {
            vehicleGroups = module._applyHeadwaysFilterForGroupedVehicles(vehicleGroups);
        }

        return vehicleGroups
    },

    /*
     * Unwraps the vehicle groups into a flat array
     */
    _flattenVehicleGroups(vehicleGroups) {
        const vehicles = [];
        vehicleGroups.map(function(vehicleGroup) {
            for (var i in vehicleGroup) {
                vehicles.push(vehicleGroup[i]);
            }
        });
        return vehicles;
    },

    /*
     * Applies filtering
     * @param options object - filtering options
     * @return vehicle object or false
     */
    _applyVehicleFilterOptions: function(vehicle, options) {
        const prediction = parseFloat(vehicle.prediction); // Enforce numeric

        // 1. Apply Min Max
        if (options.minimumPrediction && options.minimumPrediction >= 0 && prediction < options.minimumPrediction) return false;
        if (options.maximumPrediction && options.maximumPrediction >= 0 && prediction > options.maximumPrediction) return false;

        // 2. Walk Minutes
        if (options.walkMinutes && options.walkMinutes >= 0) {
            const walkMinutes = parseFloat(options.walkMinutes); // Enforce numeric

            // 2a. Walk Time Multiplier
            if (options.walkTimeMultiplier && options.walkTimeMultiplier >= 0) {
                const alteredWalkMinutes = options.walkTimeMultiplier * walkMinutes
                if (prediction <= alteredWalkMinutes) return false;
            } else {
                if (prediction <= walkMinutes) return false;
            }

        }

        return vehicle;
    },

    /*
     * For each vehicle group (row), examine the predictions between the vehicles
     * Filters only for the 2nd prediction field, will ignore predictions beyond such
     * ie: 1, 1, 2, 3 becomes 1, 3
     * @param rows array - array of vehiclegroups
     * @return filteredRows array - array of vehiclegroups
     */
    _applyHeadwaysFilterForGroupedVehicles: function(rows) {
        let filteredRows = [];
        rows.forEach(function(vehicleGroup) {
            // Important: We only filter headways IF there are more predictions available to show
            if (vehicleGroup.length > 2) {
                for (var i = 0; i < vehicleGroup.length; i++) {

                    var currentVehicle = vehicleGroup[i];
                    var nextVehicle = vehicleGroup[i + 1];

                    // End of array
                    if (!nextVehicle) continue;

                    // 1. Get rid of dupes
                    if (currentVehicle.prediction === nextVehicle.prediction) {
                        vehicleGroup.splice(i + 1, 1);
                    } else {
                        // 2. Or Compare diff
                        let headway = nextVehicle.prediction - currentVehicle.prediction;
                        if (headway < CONSTANTS.minimumHeadwayMinutes) {
                            vehicleGroup.splice(i + 1, 1);
                        }
                    }
                }
            }
            // console.log("Push", vehicleGroup);
            filteredRows.push(vehicleGroup);
        });
        return filteredRows
    },

    /*
     * TBD for switching to clock time
     * @param vehicle object
     * @return bool
     */
    vehicleIsFarAway: function(vehicle) {
        return (vehicle.prediction > CONSTANTS.minutesToClockTimeLimit);
    }
}