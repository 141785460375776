import React, {
    Component
} from 'react';

import MassTransitHeader from './MassTransitHeader';
import MassTransitRow from './MassTransitRow.js';
import TSCommons from '@transitscreen/tscommons';
import {
    Flipper,
    Flipped
} from 'react-flip-toolkit';
import 'array-flat-polyfill';

// Icons for the mode of transit
const modeIcon = {
    "bus": '<svg height="35" preserveaspectratio="xMinYMid meet" viewbox="0 0 78 35" width="78" xmlns="http://www.w3.org/2000/svg"> <g fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> <path d="M18.708 25.295c2.405 0 4.354 1.958 4.354 4.354 0 2.405-1.95 4.35-4.354 4.35-2.397 0-4.348-1.944-4.348-4.35-.074-2.397 1.952-4.355 4.35-4.355h-.002zm55.76-18.902h-1.95c-1.65 0-3.004.904-3.004 2.103v8.177c0 1.122 1.348 2.1 3.002 2.1h3.606m-48.48.075h-6.307c-1.122 0-2.103-.9-2.103-2.1V8.496c0-1.125.905-2.102 2.1-2.102h6.306c1.125 0 2.103.902 2.103 2.1v8.25c-.073 1.206-.98 2.102-2.1 2.102v.002zm-14.037 0H7.302c-1.122 0-2.1-.9-2.1-2.1V8.496c0-1.125.903-2.102 2.1-2.102h6.305c1.123 0 2.103.902 2.103 2.1v8.25c-.154 1.206-.98 2.102-2.103 2.102h-.002v.002z"> </path> <path d="M11.656 29.573H8.353c-4.95 0-7.35-.816-7.35-4.35V5.276C.923 1.532 2.873 1 6.693 1H70.26c2.4 0 4.356 1.875 4.577 4.125.534 6.227 1.652 17.032 1.652 19.73 0 3.524-2.406 5.095-4.65 5.095h-7.507"> </path> <path d="M57.582 25.295c2.402 0 4.354 1.958 4.354 4.354 0 2.405-1.95 4.35-4.353 4.35-2.404 0-4.35-1.944-4.35-4.35 0-2.398 1.95-4.356 4.35-4.355zm2.625-6.445H53.9c-1.117 0-2.1-.897-2.1-2.102v-8.25c0-1.124.906-2.1 2.102-2.1h6.303c1.13 0 2.104.9 2.104 2.1v8.25c0 1.205-.976 2.102-2.105 2.102h.002zm-14.034 0h-6.3c-1.126 0-2.103-.897-2.103-2.102v-8.25c0-1.124.9-2.1 2.104-2.1h6.3c1.128 0 2.105.9 2.105 2.1v8.25c0 1.205-.98 2.102-2.105 2.102h-.002zm3.98 10.796H26.135"> </path> </g> </svg>',
    "subway": '<svg height="35" preserveaspectratio="xMinYMid meet" viewbox="0 0 107 35" width="107" xmlns="http://www.w3.org/2000/svg"> <path d="M104.57 5.875h-1.876c-1.572 0-2.844.902-2.844 1.95v7.725c0 1.052 1.27 1.95 2.844 1.95h3.45M2.725 5.875h1.877c1.575 0 2.85.902 2.85 1.95v7.725c0 1.052-1.275 1.95-2.85 1.95H2.124m46.426.153H33.925c-1.05 0-1.95-.9-1.95-1.95V7.826c0-1.048.9-1.95 1.95-1.95H48.55c1.05 0 1.948.902 1.948 1.95v7.798c0 1.127-.898 2.03-1.95 2.03zm24.75 0H58.673c-1.05 0-1.95-.9-1.95-1.95V7.826c0-1.048.9-1.95 1.95-1.95H73.3c1.05 0 1.948.902 1.948 1.95v7.798c-.078 1.127-.977 2.03-1.95 2.03zM1 34h98.323M81.55 28.15V7.526c0-.9.675-1.574 1.577-1.574h8.925c.898 0 1.572.674 1.572 1.574v20.4m-79.952.224V7.526c0-.9.677-1.574 1.58-1.574h8.923c.9 0 1.574.674 1.574 1.574v20.4" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M102.694 27.998H5.424c-1.95 0-3.598-1.652-3.598-3.598l.9-19.798c0-1.95 1.65-3.602 3.6-3.602h94.718c1.955 0 3.605 1.65 3.605 3.602l1.72 19.798c-.072 1.946-1.652 3.598-3.676 3.598z" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> </svg>',
    "train": '<svg height="35" preserveaspectratio="xMinYMid meet" viewbox="0 0 103 35" width="103" xmlns="http://www.w3.org/2000/svg"> <g fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> <path d="M14.416 10.792h-3.33c-1.077 0-1.958-.88-1.958-1.958v-1.86c0-1.077.882-1.958 1.958-1.958h3.33c1.077 0 1.958.88 1.958 1.958v1.86c0 1.077-.88 1.958-1.958 1.958zM43.89 10.792h-3.33c-1.076 0-1.957-.88-1.957-1.958v-1.86c0-1.077.88-1.958 1.958-1.958h3.33c1.078 0 1.96.88 1.96 1.958v1.86c-.098 1.077-.98 1.958-1.96 1.958zM57.307 10.792H53.88c-1.08 0-1.96-.88-1.96-1.958v-1.86c0-1.077.88-1.958 1.96-1.958h3.327c1.08 0 1.96.88 1.96 1.958v1.86c0 1.077-.88 1.958-1.86 1.958zM70.725 10.792h-3.332c-1.076 0-1.957-.88-1.957-1.958v-1.86c0-1.077.88-1.958 1.957-1.958h3.332c1.075 0 1.957.88 1.957 1.958v1.86c-.097 1.077-.98 1.958-1.957 1.958zM22.543 28.027V6.974c0-1.077.88-1.958 1.96-1.958h5.972c1.078 0 1.958.88 1.958 1.958v21.053M92.95 10.792h-7.933c-1.076 0-1.96-.88-1.96-1.958v-1.86c0-1.077.884-1.958 1.96-1.958h1.37M1 34h99.198"> </path> <path d="M97.557 28.027H7.17c-1.96 0-3.624-1.664-3.624-3.623V4.624C3.546 2.664 5.21 1 7.17 1h66.295c17.332 0 26.05 18.898 27.32 22.23.783 1.958-.297 4.21-2.352 4.7-.29.097-.585.097-.876.097z"> </path> <path d="M34.98 15.003h40.343c3.33 0 10.088.98 12.926 2.84l12.928 6.66" stroke-linejoin="round"> </path> <path d="M3.546 15.003h16.45"> </path> </g> </svg>',
    "tram": '<svg height="35" preserveaspectratio="xMinYMid meet" viewbox="0 0 73 35" width="73" xmlns="http://www.w3.org/2000/svg"> <path d="M1 34h65.787m1.36-21.067h-2.66c-4.384 0-1.007 11.645 6.26 11.645M4.02 12.933h1.365c1.148 0 2.083.647 2.083 1.44v8.845c0 .787-.935 1.435-2.083 1.435H4.022m32.925.076h-4.96c-.863 0-1.583-.647-1.583-1.444v-8.984c0-.788.72-1.437 1.583-1.437h4.96c.864 0 1.58.65 1.58 1.437v8.984c0 .794-.716 1.443-1.58 1.443zm-15.527 0h-4.96c-.866 0-1.584-.647-1.584-1.444v-8.984c0-.788.718-1.437 1.583-1.437h4.96c.86 0 1.583.65 1.583 1.437v8.984c-.002.794-.724 1.443-1.583 1.443z" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M64.842 29.184H6.68c-1.436 0-2.658-1.225-2.658-2.66V11.928c0-1.438 1.223-2.66 2.657-2.66h56.87c4.673 0 8.27 11.36 8.27 14.664 0 3.314-4.963 5.252-6.978 5.252zM12.432 1l3.092 3.09c.574.575.574 1.44 0 2.015L12.36 9.27" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M50.03 1l3.095 3.09c.576.575.576 1.44 0 2.015L49.89 9.338M1.574 1h65.785M45.934 29.4V14.3c0-.645.578-1.15 1.363-1.15h7.623c.723 0 1.367.506 1.367 1.15v14.953M51.04 13.15v16.034" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> </svg>',
    "streetcar": '<svg height="35" preserveaspectratio="xMinYMid meet" viewbox="0 0 73 35" width="73" xmlns="http://www.w3.org/2000/svg"> <path d="M1 34h65.787m1.36-21.067h-2.66c-4.384 0-1.007 11.645 6.26 11.645M4.02 12.933h1.365c1.148 0 2.083.647 2.083 1.44v8.845c0 .787-.935 1.435-2.083 1.435H4.022m32.925.076h-4.96c-.863 0-1.583-.647-1.583-1.444v-8.984c0-.788.72-1.437 1.583-1.437h4.96c.864 0 1.58.65 1.58 1.437v8.984c0 .794-.716 1.443-1.58 1.443zm-15.527 0h-4.96c-.866 0-1.584-.647-1.584-1.444v-8.984c0-.788.718-1.437 1.583-1.437h4.96c.86 0 1.583.65 1.583 1.437v8.984c-.002.794-.724 1.443-1.583 1.443z" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M64.842 29.184H6.68c-1.436 0-2.658-1.225-2.658-2.66V11.928c0-1.438 1.223-2.66 2.657-2.66h56.87c4.673 0 8.27 11.36 8.27 14.664 0 3.314-4.963 5.252-6.978 5.252zM12.432 1l3.092 3.09c.574.575.574 1.44 0 2.015L12.36 9.27" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M50.03 1l3.095 3.09c.576.575.576 1.44 0 2.015L49.89 9.338M1.574 1h65.785M45.934 29.4V14.3c0-.645.578-1.15 1.363-1.15h7.623c.723 0 1.367.506 1.367 1.15v14.953M51.04 13.15v16.034" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> </svg>',
    "cable-car": '<svg height="35" preserveaspectratio="xMinYMid meet" viewbox="0 0 71 35" width="71" xmlns="http://www.w3.org/2000/svg"> <path d="M55.705 29.113v-17.38c0-.722.56-1.283 1.282-1.283h5.203c.724 0 1.28.562 1.28 1.283v17.22M3.803 10.45H18.14c.72 0 1.28.562 1.28 1.283v17.14M2.522 34h66.8" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M66.597 10.45v15.62c0 1.6-1.357 2.963-2.964 2.963H6.847c-1.602 0-2.963-1.363-2.963-2.964V10.45" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M13.815 6.767V3.963h-2.883S12.294 1 13.896 1h42.53c1.603 0 2.962 2.963 2.962 2.963h-2.88v2.804M3.884 12.613V10.53H1s1.36-3.685 2.963-3.685h62.472c1.605 0 2.965 3.685 2.965 3.685h-2.883v2.083" fill="none" stroke="#30A7DD" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M32.557 18.94h-4.645c-.88 0-1.604-.72-1.604-1.603v-5.365c0-.882.722-1.603 1.604-1.603h4.645c.88 0 1.602.72 1.602 1.602v5.365c0 .885-.72 1.603-1.603 1.603zM47.214 18.94h-4.566c-.88 0-1.6-.72-1.6-1.603v-5.365c0-.882.72-1.603 1.6-1.603h4.646c.88 0 1.602.72 1.602 1.602v5.365c-.08.885-.8 1.603-1.682 1.603zM11.653 29.113V10.45" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> </svg>',
    "shuttle": '<svg height="35" preserveaspectratio="xMinYMid meet" viewbox="0 0 75 35" width="75" xmlns="http://www.w3.org/2000/svg"> <circle cx="16.341" cy="29.808" fill="none" r="4.192" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </circle> <path d="M61.828 25.615c2.315 0 4.19 1.878 4.19 4.192S64.142 34 61.828 34c-2.318 0-4.19-1.878-4.19-4.192s1.87-4.193 4.19-4.193zm6.776-7.222h-9.63c-1.43 0-2.59-.804-2.59-1.785V11.08c0-.982 1.16-1.785 2.59-1.785h2.942M55.05 29.808H22.94" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M9.473 29.808H4.3c-1.783 0-3.3-1.517-3.3-3.3V4.3C1 2.517 2.517 1 4.3 1h52.8c5.888 0 6.334 8.294 5.888 8.294h-2.675c-1.252 0 12.664 12.667 12.93 14.184.18 1.515.895 6.33-1.693 6.33h-3.39" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M29.005 19.017h-5.44c-.982 0-1.785-.803-1.785-1.783V9.206c0-.98.803-1.785 1.785-1.785h5.44c.98 0 1.783.807 1.783 1.788v8.027c0 .98-.802 1.784-1.783 1.784zm-14.805 0H8.76c-.982 0-1.784-.803-1.784-1.783V9.206c0-.98.802-1.785 1.783-1.785h5.44c.98 0 1.78.807 1.78 1.788v8.027c0 .98-.8 1.784-1.782 1.784z" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> </svg>',
    "gondola": '<svg height="50" preserveaspectratio="xMinYMid meet" viewbox="0 0 62.1 50" width="62.1" xmlns="http://www.w3.org/2000/svg"> <path d="M27 7.577L.1 4.1M62 12.1L33.6 8.43M50.4 26.4h-4.5c-1.6 0-2.9.9-2.9 2v12.3c0 1.1 1.3 2 2.9 2h9.2" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M33.6 20.7v-16c0-1.6-1.5-2.9-3.3-2.9S27 3.1 27 4.7v16m-16.8 5.7h4.5c1.6 0 2.9.9 2.9 2v12.3c0 1.1-1.3 2-2.9 2H5.5m29.9 6.4v-21c0-.9-.7-1.6-1.6-1.6h-7.4c-.9 0-1.6.7-1.6 1.6v20.8" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2" vector-effect="non-scaling-stroke"> </path> <path d="M30.7 49h14.8c2.9 0 9.7-2.6 9.7-7.2s-5-20.4-11.5-20.4H16.4C9.9 21.4 5 37.1 5 41.7c0 4.6 6.8 7.2 9.7 7.2h16v.1z" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2" vector-effect="non-scaling-stroke"> </path> </svg>',
    "ferry": '<svg height="35" preserveaspectratio="xMinYMid meet" viewbox=".669 0 83 35" width="83" xmlns="http://www.w3.org/2000/svg"> <path d="M10.36 18.212v-9.12c0-2.38.95-3.173 2.776-3.173h51.478c3.49 0 8.013 12.214 8.013 12.214" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M24.32 14.88h-2.855c-1.032 0-1.824-.634-1.824-1.427v-2.14c0-.796.793-1.428 1.823-1.428h2.854c1.032 0 1.826.632 1.826 1.427v2.14c0 .794-.873 1.43-1.825 1.43h.002zm17.767 0h-2.855c-1.03 0-1.824-.634-1.824-1.427v-2.14c0-.796.872-1.428 1.823-1.428h2.856c1.03 0 1.824.632 1.824 1.427v2.14c0 .795-.795 1.43-1.823 1.43zm17.767 0H57c-1.032 0-1.827-.634-1.827-1.427v-2.14c0-.796.795-1.428 1.826-1.428h2.854c1.03 0 1.823.632 1.823 1.427v2.14c.08.795-.79 1.43-1.823 1.43zm-22.13-8.96V2.425c0-.79.636-1.426 1.428-1.426h18.242c.798 0 1.428.635 1.428 1.426v3.57" fill="none" stroke="#30A7DD" stroke-miterlimit="10" stroke-width="2"> </path> <path d="M1 29.635c4.6-.952 8.487 0 12.77 1.744 1.587.635 3.173 1.426 4.918 1.902 3.172.87 7.06.87 7.694-1.428.237-.873-.317-1.744-.952-2.143h-.08c4.6-.95 8.488 0 12.77 1.746 1.587.636 3.173 1.427 4.92 1.902 3.17.873 7.058.873 7.69-1.43.24-.87-.315-1.74-.95-2.138h-.08c4.6-.953 8.492 0 12.774 1.745 1.586.634 3.172 1.425 4.915 1.903 3.176.872 7.216.95 7.695-1.428.158-.875-.873-2.142-.873-2.142 1.98-2.538 6.344-9.043 8.328-11.583-9.836 0-21.34.16-31.173.16-16.1-.396-31.488-.396-47.512-.396-.715 2.776 0 5.71.714 8.487 0 0 .634.474 1.11.474" fill="none" stroke="#30A7DD" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"> </path> </svg>'
}

class MassTransit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unfilteredRow: [...this.props.blockData.items],
            transitRows: {},
            rowLimit: this.props.blockData.row_limit === null ? 6 : this.props.blockData.row_limit,
            minPrediction: this.props.blockData.min_prediction === null ? 1 : this.props.blockData.min_prediction,
            maxPrediction: this.props.blockData.max_prediction === null ? 99 : this.props.blockData.max_prediction,
            walkMinutes: this.props.blockData.walk_minutes,
            agencyData: {
                logo: Object.values(this.props.blockData.agencies)[0].logo,
                mode: Object.values(this.props.blockData.agencies)[0].mode
            }
        };
    }

    componentDidMount() {

    }

    parseSettings() {

    }



    /*

        TODO
        Look at maybe having a data wrapper

        Animating
        https://medium.com/developers-writing/animating-the-unanimatable-1346a5aab3cd
    */
    parseData() {
        let massTransitRowArr = {},
            walkMinutes = 1;

        if (this.props.blockData.walk_minutes !== null) {
            walkMinutes = this.props.blockData.walk_minutes
        }


        this.props.blockData.items.forEach(massTransitItem => {
            // if( !(massTransitItem.group_id in massTransitRowArr) && massTransitItem.prediction >= this.state.walkMinutes && massTransitItem.prediction < 100) {
            //     massTransitRowArr[massTransitItem.group_id] = [];
            // }
            // if(massTransitItem.prediction >= this.state.walkMinutes && massTransitItem.prediction < 100){
            //     massTransitRowArr[massTransitItem.group_id].push(massTransitItem);
            // }

            if (massTransitItem.prediction >= (walkMinutes * this.props.screenData.walk_filter_multiplier) &&
                massTransitItem.prediction >= this.state.minPrediction &&
                massTransitItem.prediction < 100) {
                if (!(massTransitItem.group_id in massTransitRowArr)) {
                    massTransitRowArr[massTransitItem.group_id] = [];
                }
                if (massTransitRowArr[massTransitItem.group_id].length > 0) {
                    if (massTransitItem.prediction !== massTransitRowArr[massTransitItem.group_id][massTransitRowArr[massTransitItem.group_id].length - 1].prediction) {
                        massTransitRowArr[massTransitItem.group_id].push(massTransitItem);
                    } else {
                        // the prediction is the same, therefore not adding it to the array
                    }

                } else {
                    massTransitRowArr[massTransitItem.group_id].push(massTransitItem);
                }
                // console.log(massTransitRowArr[massTransitItem.group_id].length );
                // massTransitRowArr[massTransitItem.group_id].length > 0 ? console.log(massTransitRowArr[massTransitItem.group_id][massTransitRowArr[massTransitItem.group_id].length - 1]) : console.log(0);
                // massTransitRowArr[massTransitItem.group_id].push(massTransitItem);
            }
        });
        // console.log(massTransitRowArr);
        return massTransitRowArr;
    }


    render() {
        // let transitRows = this.parseData(),
        //     visibleTransitRows = Object.keys(transitRows).slice(0, this.state.rowLimit);
        let transitRows = TSCommons.getVehicleRows(this.props.blockData.items, {
            predictionType: "minutesAway",
            groupVehicles: true,
            filterHeadways: true,
            walkMinutes: this.props.blockData.walk_minutes,
            walkTimeMultiplier: this.props.screenData.walk_filter_multiplier === 0 ? 0.1 : this.props.screenData.walk_filter_multiplier,
            minimumPrediction: this.props.blockData.min_prediction === null ? 1 : this.props.blockData.min_prediction,
            maximumPrediction: this.props.blockData.max_prediction === null ? 99 : this.props.blockData.max_prediction,
            rowLimit: this.props.blockData.row_limit === null ? 6 : this.props.blockData.row_limit
        }),
            visibleTransitRows = transitRows.slice(0, this.state.rowLimit),
            alerts = [],
            hasAlerts = "";

        // Alphabetical Sort for Brampton
        visibleTransitRows.sort((a, b) => {
            if (a[0].destination < b[0].destination) {
                return -1;
            }
            if (b[0].destination > a[0].destination) {
                return 1;
            }
            return 0;
        });

        // console.log('transitRows');
        // console.log(visibleTransitRows);

        for (var i = 0; i < visibleTransitRows.length; i++) {
            for (var j = 0; j < visibleTransitRows[i].length; j++) {
                if (visibleTransitRows[i][j].alert_ids.length > 0) {
                    hasAlerts = "has-alerts";
                    alerts.push(visibleTransitRows[i][j].alert_ids);
                }
            }
        }
        alerts = [...new Set(alerts.flat())]
        // console.log('alerts');
        // console.log(alerts);

        return (
            <div className={this.props.cssClasses + " " + this.state.agencyData.mode + " " + hasAlerts}>

                <header className="block-header">
                    <div className="block-logos">
                        <span className="mode-icon" dangerouslySetInnerHTML={{ __html: modeIcon[this.state.agencyData.mode] }}></span>
                        {Object.keys(this.props.blockData.agencies).map((agency, index) =>
                            <span key={index} className="agency-logo" dangerouslySetInnerHTML={{ __html: this.props.blockData.agencies[agency]["logo"] }}></span>
                            // console.log(this.props.blockData.agencies[agency]["logo"])
                        )}
                    </div>
                </header>

                <Flipper className="flip" flipKey={transitRows} spring={{ stiffness: 300, damping: 90 }} staggerConfig={{ default: { reverse: "reverse", speed: 0 } }}>

                    <MassTransitHeader {...this.props} {...this.state} />
                    {
                        visibleTransitRows.length === 0 ? <div className="error-message"><span className="">{this.props.localization.translations[this.props.localization.currentLanguage]["ts5s"]}</span></div> : ""
                    }
                    {/* play around with timing
                        https://codepen.io/aholachek/pen/bKmZbV
                    */}
                    <Flipped flipId="rows" >
                        <div>
                            {visibleTransitRows.map((row, index) =>

                                <MassTransitRow
                                    key={row[0]["group_id"]}
                                    id={row[0]["group_id"]}
                                    firstPrediction={row[0]["prediction"]}
                                    stagger={"reverse"}
                                    massTransitRow={row.slice(0, 3)} // showing three predictions
                                    rowLimit={this.state.rowLimit}
                                    screenData={this.props.screenData}
                                    alertIDs={alerts}
                                    alertsArr={this.props.alerts}
                                    localization={this.props.localization}
                                />

                            )}
                        </div>
                    </Flipped>
                </Flipper>


            </div >
        );
    }
}

MassTransit.displayName = "MassTransit";
export default MassTransit;
