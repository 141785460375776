import React, {
    Component
} from 'react';

const congestionColors = {
    'clear': '#6EBE71',
    'light': '#F7E966',
    'moderate': '#FBA541',
    'heavy': '#FF0000',
    'extreme': '#930000',
};

class TrafficV2 extends Component {

    constructor(props) {
        super(props);

        this.state = {

            rowLimit: null,
            walkMinutes: null,
        };
    }

    componentDidMount() {
        this.parseSettings();
        // this.parseData();
    }

    parseSettings() {
        this.setState({
            maxPredictions: 3, //this.props.blockData.max_predictions
            rowLimit: this.props.blockData.row_limit,
        });
    }

    parseData() {
        this.setState({
            dockedRows: this.props.blockData.items,
        });
    }

    /*
        TODO:
            * add inrix logo
            * add route background image

    */

    getCongestionIndicator(route) {
        let phrase = 'clear';

        if (route && route.current_congestion) {
            phrase = route.current_congestion;
        }

        const color = congestionColors[phrase];
        const formattedPhrase = `${route.current_congestion[0].toUpperCase()}${route.current_congestion.substring(1)}`

        return (<span><span className="dot" style={{backgroundColor: color}}></span>{formattedPhrase}</span>);
    }

    render() {
        return (
            <div className={this.props.cssClasses}>
                <h2 className="content-container__header traffic-v2__times-header">{this.props.blockData.title || this.props.localization.translations[this.props.localization.currentLanguage]["ts8w"]}</h2>
                <div className="traffic-v2__table">
                    <div className="content-container__row traffic-v2__row traffic-v2__row__header content-container__row__header" >
                        <span className="traffic-v2__row__destination">{this.props.localization.translations[this.props.localization.currentLanguage]["to"].toLowerCase()}</span>
                        <span className="traffic-v2__row__route">{this.props.localization.translations[this.props.localization.currentLanguage]["take"].toLowerCase()}</span>
                        <span className="traffic-v2__row__prediction"><span>{this.props.localization.translations[this.props.localization.currentLanguage]["currently"].toLowerCase()}</span></span>
                        <span className="traffic-v2__row__congestion"><span>{this.props.localization.translations[this.props.localization.currentLanguage]["congestion"]}</span></span>
                    </div>
                    {this.props.blockData.routes.map((route, key) =>
                        <div key={key} className="content-container__row traffic-v2__row">
                            <span className="traffic-v2__row__destination">{route.destination_name}</span>
                            <span className="traffic-v2__row__route"><span className={route.route_class}>{route.route_number || route.route_description}</span></span>
                            <span className="traffic-v2__row__prediction">
                                <span>{route.current_drive_minutes}</span><span className="traffic-v2__row__prediction__unit">min</span>
                            </span>
                            <span className="traffic-v2__row__congestion">
                                {this.getCongestionIndicator(route)}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

TrafficV2.displayName = "Traffic";
export default TrafficV2;
