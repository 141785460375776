import React from 'react';

const Parking = (props) => {
    const { blockData, cssClasses, localization } = props;

    const lang = localization.translations[localization.currentLanguage];

    // console.log(props.blockData);

    return (
        <div className={cssClasses}>

            <Header blockData={blockData} />

            <Rows blockData={blockData} lang={lang} />

        </div>
    )
}

const Header = ({ blockData }) => {

    let label = "";
    if (blockData.parking && blockData.parking[0]) {
        if (blockData.parking[0].pricePerHour) {
            label = "Price Per Hour";
        } else if (blockData.parking[0].parkingTypes) {
            label = "Spaces Available";
        } else if (blockData.parking[0].predictionString) {
            label = "Exit Wait Time (min)";
        } else if (blockData.parking[0].first_hour_price) {
            label = "First Hour Price";
        }
    }

    return (
        <h2 className="content-container__header">
            <span className="title">
                {blockData.title || 'Parking Garage'}
            </span>
            <span className="prediction">
                {label}
            </span>
        </h2>
    )
}

const Rows = ({ blockData, lang }) => {

    const { items, parking, row_limit } = blockData;

    let dataItems = parking || items;

    if (!dataItems || !dataItems.length) return <div className="error-message">{lang['ts5z']}</div>

    return (
        <div className="rows">
            {dataItems.map((item, i) => {
                if (row_limit && i > row_limit - 1) return null;

                let symbol = item.currency === 'USD' ? '$' : '';

                let prediction = item.predictionString && item.predictionString.replace("Minute Wait", "") || '--';
                if (item.pricePerHour) {
                    const padded = item.pricePerHour.toString().split(".");
                    let rightPad = padded[1] || '00';
                    if (padded[1] && padded[1].length === 1) {
                        rightPad = `${padded[1]}0`;
                    }
                    let leftPad = padded[0] || '';

                    prediction = `${symbol}${leftPad}.${rightPad}`;
                } else if (item.parkingTypes) {
                    prediction = item.parkingTypes.map((garage) => {
                        let spaces = garage.type !== 'General' ? `${garage.type} spaces` : 'spaces';
                        return (
                            <div className="prediction">
                                {garage.availableSpaceCount} <span className="unit">{spaces}</span>
                            </div>
                        );
                    });
                } else if (item.first_hour_price) {
                    prediction = `${symbol}${item.first_hour_price}`
                }

                return (
                    <div key={i} className="content-container__row">
                        <div className="location">
                            {item.name}
                        </div>
                        <div className="prediction">
                            <div className="color" style={{ backgroundColor: item.backgroundColor }}></div>
                            {prediction}
                        </div>
                    </div>
                );
            })}
        </div>
    )
}

export default Parking;

