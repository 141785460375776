import React, {
    Component
} from 'react';

import AgencyAlerts from './AgencyAlerts.js';
import MassTransitHeader from './MassTransitHeader';
import MassTransitRow from './MassTransitRow.js';
import TSCommons from '@transitscreen/tscommons';
import {
    Flipper,
    Flipped
} from 'react-flip-toolkit';
import 'array-flat-polyfill';

class MassTransit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unfilteredRow: [...this.props.blockData.items],
            transitRows: {},
            rowLimit: this.props.blockData.row_limit === null ? 6 : this.props.blockData.row_limit,
            minPrediction: this.props.blockData.min_prediction === null ? 1 : this.props.blockData.min_prediction,
            maxPrediction: this.props.blockData.max_prediction === null ? 99 : this.props.blockData.max_prediction,
            walkMinutes: this.props.blockData.walk_minutes,
            agencyData: {
                logo: Object.values(this.props.blockData.agencies)[0].logo,
                mode: Object.values(this.props.blockData.agencies)[0].mode
            }
        };
    }

    componentDidMount() {

    }

    getMassTransitRows(visibleTransitRows) {
        if (visibleTransitRows.length === 0) {
            return (
                <div className="error-message">
                    <span className="">{this.props.localization.translations[this.props.localization.currentLanguage]["ts5s"]}</span>
                </div>
            );
        }
        return visibleTransitRows.map((row, index) =>
            <MassTransitRow
                key={row[0]["group_id"] }
                id={row[0]["group_id"] }
                firstPrediction={row[0]["prediction"]}
                stagger={"reverse"}
                massTransitRow={row.slice(0, 2)} // showing two predictions
                rowLimit={this.state.rowLimit}
                screenData={this.props.screenConfig && this.props.screenConfig.screen || null}
                screenConfig={this.props.screenConfig}
                context={this.props.context}
                alertsArr={this.props.alerts}
                localization={this.props.localization}
                />
        );
    }

    getVisibleAlerts(visibleTransitRows) {
        if (visibleTransitRows.length === 0) return null;
        let alerts = [];
        for (var i = 0; i < visibleTransitRows.length; i++) {
            for (var j = 0; j < visibleTransitRows[i].length; j++) {
                if (visibleTransitRows[i][j].alert_ids.length > 0) {
                    alerts.push(visibleTransitRows[i][j].alert_ids);
                }
            }
        }
        alerts = [...new Set(alerts.flat())];
        // console.log(alerts);
        return alerts;
    }

    getAgencyAlertIds() {
        let agencyAlerts = [];
        Object.keys(this.props.blockData.agencies).map((agency, index) =>
            agencyAlerts.push(this.props.blockData.agencies[agency]["alert_ids"])
        );
        agencyAlerts = [...new Set(agencyAlerts.flat())];
        // console.log(agencyAlerts);
        return agencyAlerts;
    }

    render() {

        // There's probably a more performant place to put this row filtering function but for now its part of render
        let transitRows = TSCommons.getVehicleRows(this.props.blockData.items, {
            predictionType: "minutesAway",
            groupVehicles: true,
            filterHeadways: true,
            walkMinutes: this.props.blockData.walk_minutes,
            walkTimeMultiplier: this.props.screenData.walk_filter_multiplier === 0 ? 0.1 : this.props.screenData.walk_filter_multiplier,
            minimumPrediction: this.props.blockData.min_prediction === null ? 1 : this.props.blockData.min_prediction,
            maximumPrediction: this.props.blockData.max_prediction === null ? 99 : this.props.blockData.max_prediction,
            rowLimit: this.props.blockData.row_limit === null ? 6 : this.props.blockData.row_limit
        });

        let visibleTransitRows = transitRows.slice(0, this.state.rowLimit);

        let visibleAlerts = this.getVisibleAlerts(visibleTransitRows);
        let agencyAlertIds = this.getAgencyAlertIds();
        let hasAlerts = visibleAlerts && visibleAlerts.length > 0 ? 'has-alerts' : '';
        let hasAgencyAlerts = agencyAlertIds && agencyAlertIds.length > 0 ? 'has-agency-alerts' : '';

        // console.log('transitRows');
        // console.log(visibleTransitRows);

        return (
            <div className={this.props.cssClasses + " " + this.state.agencyData.mode + " " + hasAlerts + " " + hasAgencyAlerts}>

                <Flipper className="flip" flipKey={transitRows} spring={{ stiffness: 300, damping: 90 }} staggerConfig={{ default: {reverse: "reverse", speed: 0}}}>
                    <MassTransitHeader {...this.props} {...this.state} />
                    {this.getMassTransitRows(visibleTransitRows)}
                    <AgencyAlerts visibleAlerts={visibleAlerts} agencyAlertIds={agencyAlertIds} screenData={this.props.screenData} />
                </Flipper>

            </div>
        );
    }
}

MassTransit.displayName = "MassTransit";
export default MassTransit;
