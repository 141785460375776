import React, {
    Component
} from 'react';


class StaticImage extends Component {

    render() {


        return (
            <div className="content-container ">
                <img alt="" src={this.props.blockData.image_url} />
            </div>

        )
    }
}
StaticImage.displayName = "StaticImage";
export default StaticImage;
