import React from 'react';
import {
	ReactSVG
} from 'react-svg';

import AgencyLogo from '../components/AgencyLogo.js';

import bike from '../img/transitscreen/mode-icons/bike.svg';
import bus from '../img/transitscreen/mode-icons/bus.svg';
import car from '../img/transitscreen/mode-icons/car.svg';
import taxicab from '../img/transitscreen/mode-icons/taxicab.svg';
import ebike from '../img/transitscreen/mode-icons/ebike.svg';
import scooter from '../img/transitscreen/mode-icons/scooter.svg';
import shuttle from '../img/transitscreen/mode-icons/shuttle.svg';
import subway from '../img/transitscreen/mode-icons/subway.svg';
import streetcar from '../img/transitscreen/mode-icons/streetcar.svg';
import train from '../img/transitscreen/mode-icons/train.svg';
import dockless from '../img/transitscreen/mode-icons/dockless.svg';
import ferry from '../img/transitscreen/mode-icons/ferry.svg';
import flight from '../img/transitscreen/mode-icons/flight.svg';
import moped from '../img/transitscreen/mode-icons/moped.svg';
import cableCar from '../img/transitscreen/mode-icons/cable-car.svg';
import gondola from '../img/transitscreen/mode-icons/gondola.svg';
import highspeedrail from '../img/transitscreen/mode-icons/highspeedrail.svg';

const MODE_ICONS = {
	bus: bus,
	subway: subway,
	train: train,
	'high-speed-rail': highspeedrail,
	tram: streetcar,
	streetcar: streetcar,
	shuttle: shuttle,
	'cable-car': cableCar,
	gondola,
	ferry: ferry,
	funicular: gondola,
	bikeshare: bike,
	ebikeshare: ebike,
	carshare: car,
	rideshare: car,
	scootershare: scooter,
	'sit-scootershare': dockless,
	'dockless-bikeshare': bike,
	floating: car,
	pointtopoint: car,
	numina: bike,
	traffic: car,
	dockless: dockless,
	parking: car,
	flight: flight,
};

function BlockHeader(props) {

	const {
		agencies,
		mode,
		modes,
		blockType
	} = props;

	let uniqueModes = null;
	if (modes) {
		uniqueModes = [...new Set(modes)]; // Uniquefy
	}

	let agencyLogos = agencies && agencies.map((agency) => (agency.logo));
	if (agencyLogos) {
		agencyLogos = [...new Set(agencyLogos)]; // Uniquefy
	}

	return (
		<div className="block-header">

			<div className="border-element border-element-left"><div className="piece"></div></div>

			{mode && MODE_ICONS[mode] && (
				<ReactSVG src={MODE_ICONS[mode]}
					wrapper="span"
					className="mode-icon" />
			)}

			{uniqueModes && uniqueModes.length && uniqueModes.map((modeName, index) => {
				if (!MODE_ICONS[modeName]) return null;
				return (
					<ReactSVG
						key={index}
						src={MODE_ICONS[modeName]}
						wrapper="span"
						className="mode-icon" />
				);
			})}

			{agencies && agencyLogos.map((logo, index) =>
				<AgencyLogo logo={logo} key={index} />
			)}

			<div className="border-element border-element-right"><div className="piece"></div></div>

		</div>
	);
}

export default BlockHeader
