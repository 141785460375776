import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';

import {
	isIE, isSafari, isFirefox, isChrome
} from 'react-device-detect';

import './index.scss';

import App from './App';
import Paging from './Paging';
import config from './config.js';

const rl = document.getElementById('reactloaded');
rl.innerHTML = "true";

// Error reporting
Sentry.init({
	dsn: "https://8a880ecd35344bc689bccd6e6da1a675@o418412.ingest.sentry.io/5339740",
	release: `${config.internalAppName}@${config.version}`,
});


// Add browser compatibility tags to BODY class
const body = document.body;
if (isIE) {
	body.className += " browser-ie";
} else if (isSafari) {
	body.className += " browser-safari";
} else if (isFirefox) {
	body.className += " browser-firefox";
} else if (isChrome) {
	body.className += " browser-chrome";
}



// Render site
if (window.location.pathname === "/paging") {
	ReactDOM.render(<Paging />, document.getElementById('root'));
} else {
	ReactDOM.render(<App />, document.getElementById('root'));
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
