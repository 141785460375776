import React, {} from 'react';

// import lokalise from '../../localization/lokalise.helper';
// import { lang } from 'moment';

// This is just the Block Title and the Walk Min, see BlockHeader
const FloatingHeader = (props) => {

    let title = props.blockData.title || props.localization.translations[props.localization.currentLanguage]["ts3q"];

    const columnHeader = props.localization.translations[props.localization.currentLanguage]["ts3a"];

    const min = props.localization.translations[props.localization.currentLanguage]["ts3i"];

    return (
        <h2 className="content-container__header dockless__header">
            <span className="dockless__header__title">{title}</span>
            <span className="dockless__header__min-header">{columnHeader} ({min})</span>
        </h2>
    );
}
FloatingHeader.displayName = "FloatingHeader";
export default FloatingHeader;