import { useEffect, useState } from 'react';
import {
    Carousel
} from 'react-responsive-carousel';

import config from '../config';

const NewsTicker = () => {

    const [slides, setSlides] = useState(null);

    function getRssFeed(feed) {
        fetch(feed)
            // .then(response => response.json())
            .then(response => response.text())
            .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
            .then(data => {
                const items = data.querySelectorAll("item");
                let components = [];
                items.forEach((el, i) => {
                    components.push(
                        <div key={i} className="slide-contents">
                            <p className="title"><b>NYTimes: {el.querySelector("title").innerHTML}</b></p>
                            <p className="description">{el.querySelector("description").innerHTML}</p>
                        </div>
                    );
                });
                setSlides(components);
            });
    }

    useEffect(() => {
        getRssFeed("https://www.nytimes.com/svc/collections/v1/publish/https://www.nytimes.com/section/world/rss.xml");
    }, []);

    return (
        <div className="NewsTicker">
            <Carousel className="slide-wrap" autoPlay infiniteLoop interval={10000} showThumbs={false}>
                {slides}
            </Carousel>
        </div>);
}

export default NewsTicker