import React from 'react';

class BlockErrorHandler extends React.Component {
    constructor(props) {
        super(props);
        this.state = { errorOccurred: false };
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        console.log('block error');
        console.log(this.props.children);
        console.log(error);
        console.log(info);
    }

    render() {
        return this.state.errorOccurred ? "" : this.props.children
    }
}

BlockErrorHandler.displayName = "BlockErrorHandler";
export default BlockErrorHandler;